import db from "./firebase.config";
import React, { useState, useEffect } from "react";
import "firebase/compat/firestore";
import "firebase/storage";
import "firebase/database";
import {Link} from "react-router-dom";
import { doc, setDoc, getDoc, deleteDoc } from "firebase/firestore";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import NavPc from "./navPcEn";
import AOS from "aos";
import "aos/dist/aos.css";
import logo from "../src/renders/logo.svg";
import {Helmet} from "react-helmet";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Write = () => {
  const [info, setInfo] = useState([]);
  const [date, setDate] = useState();
  const [p1, setP1] = useState();
  const [p2, setP2] = useState();
  const [p3, setP3] = useState();
  const [p4, setP4] = useState();
  const [p5, setP5] = useState();
  const [summary, setSummary] = useState();
  const [author, setAuthor] = useState();
  const [title, setTitle] = useState();
  const [picture, setPicture] = useState();
  const [specjalizacja, setSpecjalizacja] = useState();
  // const [loggedin, setLoggedin] = useState();
  const [pass, setPass] = useState();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setTitle(-1);
  const [pageloaded, setPageloaded] = useState(false);

  // const Fetchdata = async () => {
  //   await db
  //     .collection("blog")
  //     .get()
  //     .then((querySnapshot) => {
  //       // Loop through the data and store
  //       // it in array to display
  //       querySnapshot.forEach((element) => {
  //         var data = element.data();
  //         setInfo((arr) => [...arr, data]);
  //       });
  //     });
  // };

  useEffect(() => {
    const Fetchdata = async () => {
      await db
        .collection("blog")
        .orderBy("DATE", "desc")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((element) => {
            var data = element.data();
            setInfo((arr) => [...arr, data]);
          });
        });
    };
    Fetchdata();
    AOS.init({
      duration: 2000,
    });
  }, []);

  const items = info.map((data) => {
    return (
      <div>
        <Grid
         data-aos="fade-down"
        sx={{
          my:5,
          display: {
            xs: "block",
            sm: "block",
            md: "flex",
            lg: "flex",
            xl: "flex",
          },
        }}
        container
        spacing={5}
        alignItems=""
      >
        <Grid item  xs={0} md={0} lg={2}/>
        <Grid item key={data.PICTURE} xs={12} md={3} lg={3}>
          <a href={`/Aktualnosci/${data.URL}`}>

          
          <img
            width="100%"
            src={data.PICTURE}
            // src="https://images.unsplash.com/photo-1473186505569-9c61870c11f9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
            alt="KMT"
          /></a>
        </Grid>
        <Grid sx={{p:1}} item key={data.TITLE} xs={12} md={9} lg={5}>
          <Typography
            align="justify"
            id={data.TITLE}
            variant="h4"
            component="h1"
          >
            {data.TITLE}
          </Typography>

          <Typography
            align="justify"
            id={data.AUTHOR}
            variant="p"
            component="h4"
          >
            Author:<a href={data.AUTHOR+"En"}> {data.AUTHOR}</a>
            
            
            <br/> Date:  {data.DATE}<br/> Practice:{" "}
            <a href="/SpecjalizacjeEn"> {data.SPECJALIZACJA}</a>
             <hr />
          </Typography>
          <Typography
            align="justify"
            id={data.SUMMARY}
            variant="p"
            component="p"
          >
            {data.SUMMARY}
          </Typography>
          <br/>
          {/* <Link to={`/Aktualnosci/${data.TITLE}`}>przeczytaj</Link> */}
          <Button
            value={data.TITLE}
            variant="outlined"
            node="a"
            href={`/Aktualnosci/${data.URL}`}
            // onClick={(e) => setTitle(e.target.value)}
            sx={{p:1}}
          >
            Read more
          </Button>

        </Grid>
        <Grid item  xs={0} md={0} lg={2}/>
        </Grid>
        {/* <Modal
          open={title === data.TITLE}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            position: "absolute",
            
            left: "10%",
            display: "block",
          }}
        >
          <Box
            sx={{
              bgcolor: "background.paper",
              top: "10%",
            
            width: "90%",
            
            height: "100%",
            display: "block",
              p: 1,
              // position: 'absolute',
              // mt:5,
              // top: '50%',
              // left: '50%',
             
overflow: "scroll",
            
              // border: '2px solid #000',
              // borderRadius:10,
              // boxShadow: 2,
              // p: 4,
            }}
          >
            <Typography id={data.TITLE} variant="h3" component="h2">
              {data.TITLE}
            </Typography>
            <Typography id={data.SUMMARY} sx={{ py: 1 }}>
              <Typography variant="p" component="p" sx={{ py: 1 }}>
                Dzień publikacji: {data.DATE}
              </Typography>
              <Typography id={data.AUTHOR} variant="p" component="p">
                Autor: <a href={data.AUTHOR}>{data.AUTHOR}</a>
              </Typography>
              <Typography variant="p" component="p" sx={{ py: 1 }}>
                Specjalności: <a href="/Specjalizacje">{data.SPECJALIZACJA}</a>
              </Typography>
              <Typography
                align="justify"
                variant="h6"
                component="h4"
                sx={{ py: 1 }}
              >
                {data.P1}
              </Typography>
              <Typography
                align="justify"
                variant="h6"
                component="h4"
                sx={{ py: 1 }}
              >
                {data.P2}
              </Typography>
              <Typography
                align="justify"
                variant="h6"
                component="h4"
                sx={{ py: 1 }}
              >
                {data.P3}
              </Typography>
              <Typography
                align="justify"
                variant="h6"
                component="h4"
                sx={{ py: 1 }}
              >
                {data.P4}
              </Typography>
              <Typography
                align="justify"
                variant="h6"
                component="h4"
                sx={{ py: 1 }}
              >
                {data.P5}
              </Typography>
            </Typography>
            
            <br />
            <Button variant="outlined" onClick={handleClose}>
              Zamknij
            </Button>
          </Box>
        </Modal> */}
        <Helmet>
          <title>
         KMT Aktualnosci
          </title>
          <meta name="description" content="KMT blog, KMT aktualnosci"
          />
              <meta name="keywords" content="KMT, Kozubek Matusiak  Radcowie Prawni spółka cywilna, KMT Kozubek Matusiak , KMT Legal, KMT Kozubek Matusiak 
    Jan Matusiak, KMT, Aktualnosci prawne, blog"/>
          <link rel="canonical" href={`https://kmtlegal.pl/AktualnosciEn/${data.URL}`}/>
        </Helmet>
      </div>
    );
  });

  return (
    <div sx={{minHeight:"100vh"}}>
      <NavPc />
      
        {items}
     
    </div>
  );
};

export default Write;
