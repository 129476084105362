import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import { Button, CardActionArea, CardActions } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import AOS from "aos";
import "aos/dist/aos.css";
import NavPc from "./navPcEn";
import Jakub from "./renders/Jakub.webp";
import Jan from "./renders/Jan.webp";
import Maciej from "./renders/Maciej.webp";
import Daria from "./renders/Daria.webp";
import Waclaw from "./renders/Waclaw.webp";
import {Helmet} from "react-helmet";
const Partners = [
  {
    head: "Jakub Kozubek",
    bio: "Jakub KozubekEn",
    text: "attorney-at-law",
    phone: "+48690720320",
    email: "dasdas@email.com",
   pic: <CardMedia
   
    component="img"
    alt="no img"
    width="100%"
    image={Jakub}
    sx={{borderRadius:5,
      "&:hover": {
        opacity: "80%"
      }
    }}
  />,
  },
  {
    head: "Jan Matusiak",
    bio: "Jan MatusiakEn",
    text: "attorney-at-law",
    phone: "+48690720320",
    email: "dasdas@email.com",
    pic: <CardMedia
  component="img"
  alt="no img"
  width="100%"
  image={Jan}
  sx={{borderRadius:5,
    "&:hover": {
      opacity: "80%"
    }
  }}
/>,
  },
//   {
//     head: "Maciej Truszkiewicz",
//     bio: "Maciej TruszkiewiczEn",
//     text: "attorney-at-law",
//     phone: "+48690720320",
//     email: "dasdas@email.com",
//     pic: <CardMedia
//   component="img"
//   alt="no img"
//   width="100%"
//   image={Maciej}
//   sx={{borderRadius:5,
//     "&:hover": {
//       opacity: "80%"
//     }
//   }}
// />,
//   },
];
const OfCounsel = [
  {
    head: "Wacław Chmiel",
    bio: "Waclaw ChmielEn",
    text: "restructuring advisor",
    pic: <CardMedia
  component="img"
  alt="no img"
  width="100%"
  image={Waclaw}
  sx={{borderRadius:5,
  }}
/>,
  },
  {
    head: "Daria Meysztowicz",
    bio: "Daria MeysztowiczEn",
    text: "attorney-at-law",
    pic: <CardMedia
    component="img"
    alt="no img"
    width="100%"
    image={Daria}
    sx={{borderRadius:5,
    }}
  />,
  },
];

function PricingContent() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      {/* Hero unit */}
      <NavPc />

      <Container sx={{ py: 4, minHeight: "101vh" }} maxWidth="lg">
        {/* End hero unit */}
        <Typography
          variant="h5"
          align="left"
          color="text.#fff"
          component="h1"
          gutterBottom
        >
          <br />
          Partners
          <hr />
        </Typography>
        <Grid container spacing={12}>
          {Partners.map((card) => (
            <Grid item key={card.head} xs={12} sm={6} md={6}>
              <Card
                data-aos="zoom-in"
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardActionArea component="a" href={card.bio}>
                  {/* <CardMedia
                    component="img"
                    sx={
                      {
                        16:9
                        pt: "56.25%",
                      }
                    }
                    image={card.pic}
                    alt="loading"
                  /> */}
                </CardActionArea>
                <CardContent sx={{ flexGrow: 1 }}>
                  <a href={card.bio}>{card.pic}</a>

                  <Typography gutterBottom variant="h5" component="h5">
                    {card.head}
                  </Typography>
                  <Typography>{card.text}</Typography>
                </CardContent>

                {/* <Button component="a" href={card.bio} size="small">O mnie</Button>
                  <Button component="a" href={card.phone} size="small">{card.phone}</Button>
                  
                  <Button component="a" href={card.email} size="small">{card.email}</Button>
                  <Button component="a" href={card.in} size="small">LinkedIN</Button> */}
              </Card>
            </Grid>
          ))}
        </Grid>
        <Typography
          variant="h5"
          align="left"
          color="text.secondary"
          component="p"
          gutterBottom
        >
          <br />
          Of Counsel
          <hr />
        </Typography>
        <Grid container spacing={12}>
          {OfCounsel.map((cardd) => (
           <Grid item key={cardd.head} xs={12} sm={6} md={6}>
           <Card
             data-aos="zoom-in"
             sx={{
               height: "100%",
               display: "flex",
               flexDirection: "column",
             }}
           >
             <CardActionArea component="" >
               {/* <CardMedia
                 component="img"
                 sx={
                   {
                     16:9
                     pt: "56.25%",
                   }
                 }
                 image={card.pic}
                 alt="loading"
               /> */}
             </CardActionArea>
             <CardContent sx={{ flexGrow: 1 }}>
             <a href={cardd.bio}>{cardd.pic}</a>
               <Typography gutterBottom variant="h5" component="h5">
                 {cardd.head}
               </Typography>
               <Typography>{cardd.text}</Typography>
             </CardContent>

             {/* <Button component="a" href={card.bio} size="small">O mnie</Button>
               <Button component="a" href={card.phone} size="small">{card.phone}</Button>
               
               <Button component="a" href={card.email} size="small">{card.email}</Button>
               <Button component="a" href={card.in} size="small">LinkedIN</Button> */}
           </Card>
         </Grid>
          ))}
        </Grid>
        <Helmet>
        
        <title>
        KMT - nasz zespół
        </title>
        <meta name="description" content="Zespół KMT"
        />
        <link rel="canonical" href={`http://kmtlegal.pl/KMT`}/>
      </Helmet>
      </Container>
      {/* End hero unit */}
    </React.Fragment>
  );
}

export default function Pricing() {
  return <PricingContent />;
}
