import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Iframe from "react-iframe";
import emailjs from "@emailjs/browser";
import TextField from "@mui/material/TextField";
import Cookies from "./cookies.jsx";
import logo from "./svg/kmt-z-zielony-czerwony.svg";
import InfoIcon from "@mui/icons-material/Info";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import Alert from "@mui/material/Alert";

function Copyright() {
  return (
    <Typography variant="body2" color="white" align="center">
      <br />
      Strona wykorzystuje pliki cookies. W ramach naszej witryny stosujemy pliki
      cookies w celu świadczenia Państwu usług na najwyższym poziomie, w tym w
      sposób dostosowany do indywidualnych potrzeb. Korzystanie z witryny bez
      zmiany ustawień dotyczących cookies oznacza, że będą one zamieszczane w
      Państwa urządzeniu końcowym. Możecie Państwo dokonać w każdym czasie
      zmiany ustawień dotyczących cookies.
      <br />
      <Link color="inherit" href="/cookies">
        Naciśnij po więcej szczegółów o Polityce Prywatności i Cookies.
      </Link>{" "}
      <br />
      <Link color="inherit" href="/AML-RODO">
        Naciśnij po więcej szczegółów o AML-RODO.
      </Link>{" "}
      <hr />
      {
        "Copyright © Kozubek Matusiak  Radcowie Prawni s.c."
      }{" "}
      {new Date().getFullYear()}
      <br />
      <Link color="inherit" href="https://cholujdesign.com/">
        Wykonanie strony: CholujDesign S.C.
      </Link>{" "}
      {"."}
    </Typography>
  );
}

function refreshPage() {
  window.location.reload(false);
}

const theme = createTheme({
  palette: {
    primary: {
      light: "#ffffff",
      main: "#825b07",
      dark: "#825b07",
      contrastText: "#000",
    },
    secondary: {
      light: "#022911",
      main: "#022911",
      dark: "#022911",
      contrastText: "#fff",
    },
  },
  shadows: ["none"],
  typography: {
    fontFamily: ["Lato"].join(","),
    textTransform: "none",
    button: {
      textTransform: "none",
    },
  },
});

export default function App() {
  const [wyslanoWiadomosc, setwyslanoWiadomosc] = React.useState(false);
  const [validated, setValidated] = React.useState(false);
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_6kxdm78",
        "template_m6wdswk",
        e.target,
        "mx4eNYcU6K_qE927r"
      )
      .then(
        (result) => {
          console.log(result.text);
          setwyslanoWiadomosc(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }
  
  const Wyslano = () => {
    return (
      <div>
        {wyslanoWiadomosc ? (
          <Alert
            sx
            // action={
            //   <Button onClick={refreshPage} color="inherit" size="small">
            //     X
            //   </Button>
            // }
          >
           Message sent
          </Alert>
        ) : null}
      </div>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main>
        {/* Footer */}
        <Box sx={{ bgcolor: "primary.light", mt: 0, p: 0 }} component="footer">
          <Grid container spacing={0}>
            <Grid
              item
              key="24"
              xs={12}
              sm={12}
              md={12}
              // data-aos="fade-left"
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
                width: "100%",
                flexDirection: "column",
              }}
            >
              <Iframe
                url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2562.1140072297294!2d19.94999671561108!3d50.0466955794217!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47165b4472279295%3A0xb94013a700838631!2sPiwna%2015%2C%2030-527%20Krak%C3%B3w%2C%20Polska!5e0!3m2!1spl!2sus!4v1675286443176!5m2!1spl!2sus"
                width="100%"
                height="490px"
                id="myId"
                className="iframe"
                //display="block"
                // display="initial"
                position="relative"
              />{" "}
            </Grid>
            <Grid
              item
              key="324"
              xs={12}
              sm={6}
              md={6}
              data-aos="fade-left"
              sx={{
                height: "100%",
                align: "left",
                alignItems: "left",
                pl: 2,
                // display: "flex-end",
                // flexDirection: "row",
              }}
            >
              <Typography align="left" color="" component="h1" variant="h5">
                <br />
                <img src={logo} width="100" />
                <br />
                {/* <Button
                    size="large"
                    href="https://goo.gl/maps/PiQzy4NwX1Y7FHC9A"
                    color="secondary"
                    variant=""
                  > */}
                Kozubek Matusiak  <br />
                Radcowie Prawni spółka cywilna (civil law partnership)
                {/* </Button> */}
                <br />
                <br />
                <div className="samllText" align="left">
                  Piwna 15/3 Street,
                  <br /> 30-527 Kraków, Polska
                  <Button
                    size="large"
                    href="https://goo.gl/maps/PiQzy4NwX1Y7FHC9A"
                    color="secondary"
                    variant=""
                    sx={{
                      "&:hover": {
                        backgroundColor: "#333",
                      },
                    }}
                  >
                    {" "}
                    <AddLocationAltIcon />
                  </Button>
                  <br />
                  +48 690 720 320
                  <Button
                    size="large"
                    href="tel:+48690720320"
                    color="secondary"
                    variant=""
                    sx={{
                      "&:hover": {
                        backgroundColor: "#333",
                      },
                    }}
                  >
                    <CallIcon />
                  </Button>
                  <br />
                  biuro@kmtlegal.pl
                  <Button
                    size="large"
                    href="mailto:biuro@kmtlegal.pl"
                    color="secondary"
                    variant=""
                    sx={{
                      "&:hover": {
                        backgroundColor: "#333",
                      },
                    }}
                  >
                    <EmailIcon />
                  </Button>
                  <br /> <br />
                  NIP: 9452267798
                  <br />
                  REGON: 524238078
                  <br />
                  mBank 51 1140 2004 0000 3702 8332 4753
                </div>
                {/* <Button
                  onClick={navigator.clipboard.writeText("Kozubek Matusiak  Radcowie Prawni spółka cywilna ul. Piwna 15/3,30-527 Kraków, Polska  NIP: 9452267798, REGON: 524238078, mBank 51 1140 2004 0000 3702 8332 4753")}
                  size="small"
                  color="secondary"
                  variant=""
                >
                  Skopiuj
                </Button> */}
                <br />
              </Typography>
            </Grid>
            <Grid
              item
              key="34"
              xs={12}
              sm={6}
              md={6}
              data-aos="fade-left"
              sx={{
                height: "100%",
                display: "flex-end",
                flexDirection: "row",
              }}
            >
              <Box
                sx={{
                  marginTop: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box sx={{}} component="form" onSubmit={sendEmail} noValidate={validated}>
                  <br />
                  <Typography color="" component="h2" variant="h5">
                   Contact Form
                  </Typography>

                  <Grid container spacing={2} sx={{ p: 2 }}>
                    <Grid
                      item
                      key="2f4"
                      xs={12}
                      sm={12}
                      md={12}
                      data-aos="fade-up"
                    >
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="E-mail"
                        name="from_email"
                        autoComplete="email"
                      />
                    </Grid>{" "}
                    <Grid
                      item
                      key="2f4"
                      xs={12}
                      sm={12}
                      md={6}
                      data-aos="fade-up"
                    >
                      <TextField
                        margin="normal"
                        fullWidth
                        id="name"
                        label="Name "
                        name="from_name"
                        autoComplete="name"
                      />
                    </Grid>{" "}
                    <Grid
                      item
                      key="2f4"
                      xs={12}
                      sm={12}
                      md={6}
                      data-aos="fade-up"
                    >
                      <TextField
                        multiline
                        margin="normal"
                        fullWidth
                        name="from_number"
                        label=" Phone Number"
                        type="number"
                        id="number"
                      />
                    </Grid>
                    <Grid
                      item
                      key="2ff4"
                      xs={12}
                      sm={12}
                      md={12}
                      data-aos="fade-up"
                    >
                      <TextField
                        multiline
                        margin="normal"
                        required
                        fullWidth
                        name="message"
                        label="Message"
                        type="text"
                        id="text"
                        rows={4}
                      />
                      * Mandatory.
                      <br />
                      <Wyslano />
                      <Button
                        
                        type="submit"
                        color="secondary"
                        variant="contained"
                        large
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Send 
                      </Button>
                      <br />
                      <p className="samllText" align="justify">
                      Jakub Kozubek, Jan Matusiak, jointly conducting business as Kozubek Matusiak  Radcowie Prawni spółka cywilna (civil law partnership), address: Piwna 15/3 Street, 30-527 Kraków; NIP: 9452267798; REGON: 524238078; is the personal data administrator. Personal data is processed for the purpose of handling inquiries, particularly conducting correspondence in this regard, as well as for the purpose of determining, filing or defending against potential claims. More information about the processing of personal data can be found in the Privacy Policy and Cookies, the link to which is provided below.
                      </p>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <br />
        </Box>
        <Box sx={{ backgroundColor: "secondary.main" }}>
          <br />
          <br />
          <Copyright />
          <br />
          <br />
        </Box>

        {/* End footer */}
      </main>
    </ThemeProvider>
  );
}
