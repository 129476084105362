import db from "./firebase.config";
import React, { useState, useEffect } from "react";
import "firebase/compat/firestore";
import "firebase/storage";
import "firebase/database";
import { doc, setDoc, getDoc, deleteDoc } from "firebase/firestore";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import NavPc from "./navPc";
import AOS from "aos";
import "aos/dist/aos.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Write = () => {
  const [info, setInfo] = useState([]);
  const [date, setDate] = useState("");
  const [p1, setP1] = useState(" ");
  const [p2, setP2] = useState(" ");
  const [p3, setP3] = useState(" ");
  const [p4, setP4] = useState(" ");
  const [p5, setP5] = useState(" ");
  const [p6, setP6] = useState(" ");
  const [p7, setP7] = useState(" ");
  const [p8, setP8] = useState(" ");
  const [p9, setP9] = useState(" ");
  const [p10, setP10] = useState(" ");
  const [p11, setP11] = useState(" ");
  const [p12, setP12] = useState(" ");
  const [p13, setP13] = useState(" ");
  const [p14, setP14] = useState(" ");
  const [p15, setP15] = useState(" ");
  const [p16, setP16] = useState(" ");
  const [p17, setP17] = useState(" ");
  const [p18, setP18] = useState(" ");
  const [p19, setP19] = useState(" ");
  const [p20, setP20] = useState(" ");
  const [p21, setP21] = useState(" ");
  const [p22, setP22] = useState(" ");
  const [p23, setP23] = useState(" ");
  const [p24, setP24] = useState(" ");
  const [p25, setP25] = useState(" ");
  const [p26, setP26] = useState(" ");
  const [p27, setP27] = useState(" ");
  const [p28, setP28] = useState(" ");
  const [p29, setP29] = useState(" ");
  const [p30, setP30] = useState(" ");

  const [h1, setH1] = useState(" ");
  const [h2, setH2] = useState(" ");
  const [h3, setH3] = useState(" ");
  const [h4, setH4] = useState(" ");
  const [h5, setH5] = useState(" ");
  const [h6, setH6] = useState(" ");
  const [h7, setH7] = useState(" ");
  const [h8, setH8] = useState(" ");
  const [h9, setH9] = useState(" ");
  const [h10, setH10] = useState(" ");
  const [h11, setH11] = useState(" ");
  const [h12, setH12] = useState(" ");
  const [h13, setH13] = useState(" ");
  const [h14, setH14] = useState(" ");
  const [h15, setH15] = useState(" ");
  const [h16, setH16] = useState(" ");
  const [h17, setH17] = useState(" ");
  const [h18, setH18] = useState(" ");
  const [h19, setH19] = useState(" ");
  const [h20, setH20] = useState(" ");
  const [h21, setH21] = useState(" ");
  const [h22, setH22] = useState(" ");
  const [h23, setH23] = useState(" ");
  const [h24, setH24] = useState(" ");
  const [h25, setH25] = useState(" ");
  const [h26, setH26] = useState(" ");
  const [h27, setH27] = useState(" ");
  const [h28, setH28] = useState(" ");
  const [h29, setH29] = useState(" ");
  const [h30, setH30] = useState(" ");


  const [summary, setSummary] = useState("");
  const [author, setAuthor] = useState("");
  const [title, setTitle] = useState("");
  const [picture, setPicture] = useState("");
  const [url, setUrl] = useState("");
  const [specjalizacja, setSpecjalizacja] = useState("");
  // const [loggedin, setLoggedin] = useState();
  const [pass, setPass] = useState();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setTitle(-1);
  const [art, setArt] = useState();
  const login = () => {
    localStorage.setItem("pass", pass);
    window.location.reload(false);
  };
  let loggedin = localStorage.getItem("pass");
  // const Fetchdata = async () => {
  //   await db
  //     .collection("blog")
  //     .get()
  //     .then((querySnapshot) => {
  //       // Loop through the data and store
  //       // it in array to display
  //       querySnapshot.forEach((element) => {
  //         var data = element.data();
  //         setInfo((arr) => [...arr, data]);
  //       });
  //     });
  // };
  useEffect(() => {
    const Fetchdata = async () => {
      await db
        .collection("blog")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((element) => {
            var data = element.data();
            setInfo((arr) => [...arr, data]);
            setArt(info.length + 1);
          });
        });
    };
    Fetchdata();
    AOS.init({
      duration: 2000,
    });
  }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     Fetchdata();
  //   }, 1);
  // }, []);

  // const updateDATA = async () => {
  //   const ref = doc(db, "blog", url);
  //   await setDoc(
  //     ref,
  //     {
  //       TITLE: title,
  //       AUTHOR: author,
  //       DATE: date,
  //       P1: p1,
  //       P2: p2,
  //       P3: p3,
  //       P4: p4,
  //       P5: p5,
  //       PICTURE: picture,
  //       SPECJALIZACJA: specjalizacja,
  //       SUMMARY: summary,
  //       URL: url
  //     },
  //     { merge: true }
  //   );

  //   window.location.reload(false);
  // };

  const createDATA = async () => {
    const ref = doc(db, "blog", url);
    await setDoc(ref, {
      TITLE: title,
      AUTHOR: author,
      DATE: date,
      P1: p1,
      P2: p2,
      P3: p3,
      P4: p4,
      P5: p5,
      P6: p6,
      P7: p7,
      P8: p8,
      P9: p9,
      P10: p10,
      P11: p11,
      P12: p12,
      P13: p13,
      P14: p14,
      P15: p15,
      P16: p16,
      P17: p17,
      P18: p18,
      P19: p19,
      P20: p20,
      P21: p21,
      P22: p22,
      P23: p23,
      P24: p24,
      P25: p25,
      P26: p26,
      P27: p27,
      P28: p28,
      P29: p29,
      P30: p30,
      H1: h1,
      H2: h2,
      H3: h3,
      H4: h4,
      H5: h5,
      H6: h6,
      H7: h7,
      H8: h8,
      H9: h9,
      H10: h10,
      H11: h11,
      H12: h12,
      H13: h13,
      H14: h14,
      H15: h15,
      H16: h16,
      H17: h17,
      H18: h18,
      H19: h19,
      H20: h20,
      H21: h21,
      H22: h22,
      H23: h23,
      H24: h24,
      H25: h25,
      H26: h26,
      H27: h27,
      H28: h28,
      H29: h29,
      H30: h30,
      PICTURE: picture,
      SPECJALIZACJA: specjalizacja,
      SUMMARY: summary,
      URL: url,
      
    });
    window.location.reload(false);
  };
  const deleteDATA = async () => {
    await deleteDoc(doc(db, "blog", url));
    window.location.reload(false);
  };

  //   const items = info.map((data) => {
  //     return (
  //      <div>
  //         <br/>{data.TITLE}
  //         <br/>{data.DATE}
  //         <br/>{data.P1}
  //         <br/>{data.P2}
  //         <br/>{data.P3}
  //         <br/>{data.P4}
  //         <br/>{data.P5}
  //         <br/>
  //         <br/>{data.SUMMARY}
  //         <br/>{data.AUTHOR}
  //        <img src={data.PICTURE} width="10%"/>

  //           <Button
  //                 value={data.TITLE}
  //                 className="red"
  //                 node="button"
  //                 onClick={(e) => setTitle(e.target.value)}
  //               >
  //                 <Button
  //                   value={data.TITLE}
  //                   className="red"
  //                   node="button"
  //                   onClick={(e) => {
  //                     setTitle(e.target.value);
  //                     setAuthor(data.AUTHOR);
  //                     setP1(data.P1);
  //                     setSummary(data.SUMMARY);
  //                     setDate(data.DATE);
  //                   }}
  //                 >
  //                   Edytuj
  //                 </Button>
  //               </Button>
  //           <Modal
  //         open={title === data.TITLE}
  //         onClose={handleClose}
  //         aria-labelledby="modal-modal-title"
  //         aria-describedby="modal-modal-description"
  //       >
  //         <Box sx={{
  //   position: 'absolute',
  //   top: '50%',
  //   left: '50%',
  //   transform: 'translate(-50%, -50%)',
  //   width: '90vw',
  //   height: '80vh',
  //   bgcolor: 'background.paper',
  //   border: '2px solid #000',
  //   borderRadius:10,
  //   boxShadow: 2,
  //   p: 4,
  // }}>
  //           <Typography id={data.TITLE} variant="h6" component="h2">
  //           {data.TITLE}
  //           </Typography>
  //           <Typography id={data.SUMMARY} sx={{ mt: 2 }}>

  //         {data.DATE}
  //         {data.P1}
  //         {data.SUMMARY}
  //         {data.AUTHOR}
  //           </Typography>
  //           <Button onClick={handleClose}>Zamknij</Button>
  //         </Box>
  //       </Modal>

  //       </div>
  //     );
  //   });
  const items = info.map((data) => {
    return (
      <div>
        <Grid
          data-aos="fade-down"
          sx={{
            my: 5,
            display: {
              xs: "block",
              sm: "block",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
          }}
          container
          spacing={5}
          alignItems=""
        >
          <Grid item xs={0} md={0} lg={2} />
          <Grid item key={data.PICTURE} xs={12} md={3} lg={3}>
            <img
              width="100%"
              src={data.PICTURE}
              // src="https://images.unsplash.com/photo-1473186505569-9c61870c11f9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
              alt="KMT"
            />
          </Grid>
          <Grid sx={{ p: 1 }} item key={data.TITLE} xs={12} md={9} lg={5}>
            <Typography
              align="justify"
              id={data.TITLE}
              variant="h4"
              component="h1"
            >
              {data.TITLE}
            </Typography>

            <Typography
              align="justify"
              id={data.AUTHOR}
              variant="p"
              component="h4"
            >
              Autor:<a href={data.AUTHOR}> {data.AUTHOR}</a>
              <br /> Dzień publikacji: {data.DATE}
              <br /> Dotyczy specjalizacji:{" "}
              <a href="/Specjalizacje"> {data.SPECJALIZACJA}</a>
              <br /> URL artykułu: {data.URL}
              <hr />
            </Typography>
            <Typography
              align="justify"
              id={data.SUMMARY}
              variant="p"
              component="p"
            >
              {data.SUMMARY}
            </Typography>
            <br />
            <Button
              value={data.TITLE}
              variant="outlined"
              node="button"
              onClick={(e) => setTitle(e.target.value)}
              sx={{ p: 1 }}
            >
              Przeczytaj
            </Button>
          </Grid>
          <Grid item xs={0} md={0} lg={2} />
        </Grid>
        <Modal
          open={title === data.TITLE}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            position: "absolute",

            left: "10%",
            display: "block",
          }}
        >
          <Box
            sx={{
              bgcolor: "background.paper",
              top: "10%",

              width: "90%",

              height: "100%",
              display: "block",
              p: 1,
              // position: 'absolute',
              // mt:5,
              // top: '50%',
              // left: '50%',

              overflow: "scroll",

              // border: '2px solid #000',
              // borderRadius:10,
              // boxShadow: 2,
              // p: 4,
            }}
          >
            <Typography id={data.TITLE} variant="h3" component="h2">
              {data.TITLE}
            </Typography>
            <Typography id={data.SUMMARY} sx={{ py: 1 }}>
              <Typography variant="p" component="p" sx={{ py: 1 }}>
                Dzień publikacji: {data.DATE}
              </Typography>
              <Typography id={data.AUTHOR} variant="p" component="p">
                Autor: <a href={data.AUTHOR}>{data.AUTHOR}</a>
              </Typography>
              <Typography id={data.URL} variant="p" component="p">
                URL: <a href={data.URL}>{data.URL}</a>
              </Typography>
              <Typography variant="p" component="p" sx={{ py: 1 }}>
                Specjalności: <a href="/Specjalizacje">{data.SPECJALIZACJA}</a>
              </Typography>
              <Typography
                align="justify"
                variant="h6"
                component="h4"
                sx={{ py: 1 }}
              >
                {data.P1}
              </Typography>
              <Typography
                align="justify"
                variant="h6"
                component="h4"
                sx={{ py: 1 }}
              >
                {data.P2}
              </Typography>
              <Typography
                align="justify"
                variant="h6"
                component="h4"
                sx={{ py: 1 }}
              >
                {data.P3}
              </Typography>
              <Typography
                align="justify"
                variant="h6"
                component="h4"
                sx={{ py: 1 }}
              >
                {data.P4}
              </Typography>
              <Typography
                align="justify"
                variant="h6"
                component="h4"
                sx={{ py: 1 }}
              >
                {data.P5}
              </Typography>
            </Typography>

            <br />
            <Button variant="outlined" onClick={handleClose}>
              Zamknij
            </Button>
          </Box>
        </Modal>
      </div>
    );
  });
  if (loggedin == "kmtkancelaria2024") {
    return (
      <div>
        <NavPc />

        <div>
          <Card>
            <h5>
              <br /> 1. UZUPEŁNIĆ WSZYSTKIE POLA
              <br /> 2. JEZELI AUTOREM JEST KANCELARIA TO WPISAC KMT
              <br /> 3. MOZNA WPISYWAC KILKA SPECJALZIACJI
              <br /> 4. NIE MOZNA DAC KILKU AUTOROW (link bedzie prowadzic do
              zespolu)
              <br />{" "}
              <b>
                5. URL TO ŚCIEŻKA !!! NIE UŻYWAĆ ZNAKÓW SPECJALNYCH I SPACJI
                <hr />
              </b>
              <br />
              <br />
              Dodaj nowy post:
            </h5>
            <input
              placeholder="Tytuł"
              onChange={(e) => setTitle(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="Author"
              onChange={(e) => setAuthor(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="data rr/mm/dd"
              onChange={(e) => setDate(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="podsumowanie"
              onChange={(e) => setSummary(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="zdjecie (ściażka url)"
              onChange={(e) => setPicture(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 1"
              onChange={(e) => setH1(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 1"
              onChange={(e) => setP1(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 2"
              onChange={(e) => setH2(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 2"
              onChange={(e) => setP2(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 3"
              onChange={(e) => setH3(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 3"
              onChange={(e) => setP3(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 4"
              onChange={(e) => setH4(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 4"
              onChange={(e) => setP4(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 5"
              onChange={(e) => setH5(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 5"
              onChange={(e) => setP5(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 6"
              onChange={(e) => setH6(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 6"
              onChange={(e) => setP6(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 7"
              onChange={(e) => setH7(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 7"
              onChange={(e) => setP7(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 8"
              onChange={(e) => setH8(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 8"
              onChange={(e) => setP8(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 9"
              onChange={(e) => setH9(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 9"
              onChange={(e) => setP9(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 10"
              onChange={(e) => setH10(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 10"
              onChange={(e) => setP10(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 11"
              onChange={(e) => setH11(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 11"
              onChange={(e) => setP11(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 12"
              onChange={(e) => setH12(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 12"
              onChange={(e) => setP12(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 13"
              onChange={(e) => setH13(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 13"
              onChange={(e) => setP13(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 14"
              onChange={(e) => setH14(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 14"
              onChange={(e) => setP14(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 15"
              onChange={(e) => setH15(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 15"
              onChange={(e) => setP15(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 16"
              onChange={(e) => setH16(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 16"
              onChange={(e) => setP16(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 17"
              onChange={(e) => setH17(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 17"
              onChange={(e) => setP17(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 18"
              onChange={(e) => setH18(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 18"
              onChange={(e) => setP18(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 19"
              onChange={(e) => setH19(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 19"
              onChange={(e) => setP19(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 20"
              onChange={(e) => setH20(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 20"
              onChange={(e) => setP20(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 21"
              onChange={(e) => setH21(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 21"
              onChange={(e) => setP21(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 22"
              onChange={(e) => setH22(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 22"
              onChange={(e) => setP22(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 23"
              onChange={(e) => setH23(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 23"
              onChange={(e) => setP23(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 24"
              onChange={(e) => setH24(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 24"
              onChange={(e) => setP24(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 25"
              onChange={(e) => setH25(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 25"
              onChange={(e) => setP25(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 26"
              onChange={(e) => setH26(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 26"
              onChange={(e) => setP26(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 27"
              onChange={(e) => setH27(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 27"
              onChange={(e) => setP27(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 28"
              onChange={(e) => setH28(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 28"
              onChange={(e) => setP28(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 29"
              onChange={(e) => setH29(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 29"
              onChange={(e) => setP29(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="nagłówek 30"
              onChange={(e) => setH30(e.target.value)}
            />
            <br />{" "}
            <input
              placeholder="akapit 30"
              onChange={(e) => setP30(e.target.value)}
            />
            <br />{" "}
           

            <input
              placeholder="specjalizacja"
              onChange={(e) => setSpecjalizacja(e.target.value)}
            />
            <br />
            <input placeholder="URL bez spacji i znaków specjalnych" onChange={(e) => setUrl(e.target.value)} />
            <br />
            <Button className="red" onClick={createDATA}>
              Dodaj
            </Button>
          </Card>
          <hr />
        </div>

        <div>
          <Card>
            <h5>
              Aby usunąć artykuł należy wpisać url artykułu i kliknac usun
            </h5>

            <input
              placeholder="URL artykułu"
              onChange={(e) => setUrl(e.target.value)}
            />
            <br />
            <Button className="red" onClick={deleteDATA}>
              Usun
            </Button>
          </Card>
          <hr />
        </div>
        {items}
      </div>
    );
  } else
    return (
      <div>
        <NavPc />
        <Card>
          <input
            type="password"
            placeholder="haslo"
            onChange={(e) => setPass(e.target.value)}
          />
          <Button className="red" onClick={login}>
            Zaloguj
          </Button>
        </Card>
      </div>
    );
};

export default Write;
