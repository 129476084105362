import React, { useEffect, useRef } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import ObszaryDzialalnosciEn from "./ObszaryDzialalnosciEn";
import AOS from "aos";
import "aos/dist/aos.css";
import NavPcEn from "./navPcEn";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import "aos/dist/aos.css";
import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/icons-material/Grading";
import Com from "@mui/icons-material/ConnectWithoutContact";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import BorderClearIcon from "@mui/icons-material/BorderClear";
import Realestate from "@mui/icons-material/AccountBalance";
import Employer from "@mui/icons-material/FollowTheSigns";
import Employee from "@mui/icons-material/SupportAgent";
import Court from "@mui/icons-material/Gavel";
import Spolka from "@mui/icons-material/Handshake";
import Transaction from "@mui/icons-material/Receipt";
import Upadlosc from "@mui/icons-material/MoneyOff";
import It from "@mui/icons-material/Computer";
import PhoneIcon from "@mui/icons-material/AssuredWorkload";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Button from "@mui/material/Button";

const text = [
  {
    tab: "Obsługa przedsiębiorcy",
    icon: <Employer sx={{ fontSize: "80px" }} />,
  },
  {
    tab: "Postępowania sądowe",
    icon: <Court sx={{ fontSize: "80px" }} />,
  },
  {
    tab: "Prawo spółek",
    icon: <Spolka sx={{ fontSize: "80px" }} />,
  },
  {
    tab: "Transakcje M&A",
    icon: <Transaction sx={{ fontSize: "80px" }} />,
  },
  {
    tab: "IT/TMT",
    icon: <It sx={{ fontSize: "80px" }} />,
  },
  {
    tab: "Nieruchomości",
    icon: <Realestate sx={{ fontSize: "80px" }} />,
  },
  {
    tab: "Upadłość i restrukturuzacja",
    icon: <Upadlosc sx={{ fontSize: "80px" }} />,
  },
  {
    tab: "Prawo pracy",
    icon: <Employee sx={{ fontSize: "80px" }} />,
  },
  {
    tab: "Complience / Governance",
    icon: <PhoneIcon sx={{ fontSize: "80px" }} />,
  },
];

// function PricingContent() {
//   const myRef = useRef(null);

//   const executeScroll = () => myRef.current.scrollIntoView();
//   // run this function from an event handler or an effect to execute scroll
//   useEffect(() => {
//     AOS.init({
//       duration: 2000,
//     });
//   }, []);
//   return (
//     <React.Fragment>
//       <GlobalStyles
//         styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
//       />
//       <CssBaseline />
//       <Container
//         maxWidth="l"
//         sx={{
//           position: "fixed",
//           zIndex: 5,
//           display: {
//             xs: "none",
//             sm: "none",
//             md: "block",
//             lg: "block",
//             xl: "block",
//           },
//         }}
//       >
//         <Box backgroundColor="white">
//           <NavPc />
//         </Box>
//       </Container>
//       <Container
//         sx={{
//           display: {
//             xs: "none",
//             sm: "none",
//             md: "block",
//             lg: "block",
//             xl: "block",
//           },
//         }}
//         maxWidth="lg"
//       >
//         <Box
//           sx={{
//             pt: 3,
//             pb: 3,
//             minHeight: "20vh",
//           }}
//         >
//           <Container
//             data-aos="fade-up"
//             disableGutters
//             component="main"
//             sx={{
//               pt: 0,
//               pb: 0,
//               // backgroundColor: "secondary.main"
//             }}
//           >
//             <Container
//               sx={{
//                 m: 0.2,
//                 py: 5,
//                 display: {
//                   xs: "none",
//                   sm: "none",
//                   md: "flex",
//                   lg: "flex",
//                   xl: "flex",
//                 },
//               }}
//             ></Container>

//             <Typography
//               variant="h5"
//               align="justify"
//               color=""
//               component="p"
//               gutterBottom
//             >
//               <br />
//               KMT Kozubek Matusiak Truszkiewicz. Jesteśmy kancelarią adwokatów i
//               radców prawnych, która powstała z myślą o kompleksowym wsparciu
//               podmiotów gospodarczych w celu umożliwienia im skupienia całej
//               swojej uwagi na własnej działalności. Stawiamy się w roli partnera
//               prawnego naszego Klienta, starannie słuchając jego potrzeb, aby
//               dostraczyć mu najlepszych rozwiązań.
//             </Typography>
//           </Container>
//         </Box>

//         <Box
//           sx={{
//             // backgroundImage: `url(https://images.unsplash.com/photo-1497215728101-856f4ea42174?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80)`,
//             // backgroundSize: "cover",
//             // backgroundPosition: "center",
//             // minHeight: "80vh",
//             // alignItems: "center",
//             // py: 10,
//             // backgroundColor: "secondary.main",
//             minHeight: "80vh",
//           }}
//         >
//           <Container
//             data-aos="fade-down"
//             sx={{
//               pt: 5,
//               pb: 22,
//               // backgroundColor: "secondary.main",
//             }}
//           >
//             <Typography
//               component="h4"
//               variant="h4"
//               align="center"
//               color=""
//               gutterBottom
//               sx={{
//                 backgroundColor: "primary",
//               }}
//             >
//               Specjalizacje
//               <hr />
//             </Typography>
//             <Grid
//               sx={{
//                 display: {
//                   xs: "none",
//                   sm: "none",
//                   md: "flex",
//                   lg: "flex",
//                   xl: "flex",
//                 },
//               }}
//               container
//               spacing={0}
//               alignItems=""
//             >
//               {text.map((tier) => (
//                 <Grid item key={tier.tab} xs={12} md={6} lg={4}>
//                   <Button
//                     onClick={executeScroll}
//                     size="large"
//                     color="third"
//                     // variant="outlined"
//                     sx={{
//                       "&:hover": {
//                         backgroundColor: "secondary.light",
//                       },
//                     }}
//                   >
//                     <Typography color="grey" component="h4" variant="h6">
//                       {tier.icon}
//                       <br />
//                       {tier.tab}
//                     </Typography>
//                   </Button>
//                 </Grid>
//               ))}
//             </Grid>
//           </Container>
//         </Box>
//       </Container>
//       <div ref={myRef}>
//         <ObszaryDzialalnosci />
//       </div>
//     </React.Fragment>
//   );
// }








export default function Pricing() {
  return(<div>
  <NavPcEn /><ObszaryDzialalnosciEn /></div>
  ) ;
}