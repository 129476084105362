import React, { useEffect } from "react";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AOS from "aos";
import "aos/dist/aos.css";
import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/icons-material/Grading";
import Com from "@mui/icons-material/ConnectWithoutContact";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import BorderClearIcon from "@mui/icons-material/BorderClear";
import NavPc from "./navPc";
import { Helmet } from "react-helmet";
const text = [
  {
    one: "Zespół KMT składa się ze specjalistów zajmujących się różnymi dziedzinami prawa. Dzięki temu posiadamy niezbędne narzędzia do zaadresowania wszelkich potrzeb swoich Klientów i zaoferowania im szerokiego wachlarza usług prawnych. Jednocześnie w każdą sprawę bezpośrednio zaangażowany jest przynajmniej jeden z partnerów KMT.  Kierujemy się zasadą, zgodnie z którą powierzane nam sprawy są dla nas tak samo istotne, jak ważne są dla naszych Klientów.",
    two: "W relacjach z naszymi Klientami stawiamy na responsywność i otwartą komunikację. Szczegółowo zapoznajemy się z indywidualnymi potrzebami, wartościami i specyfiką działalności naszych Klientów. Dzięki temu udzielamy wsparcia w sposób sprawny, a jednocześnie rzetelny i dopasowany do wymagań. Uważamy, że komunikacja przy pomocy zrozumiałego języka na linii prawnik-Klient jest gwarantem skutecznego wykonania usług.",
    three:
      "Priorytetem dla nas jest czas i bezpieczeństwo naszych Klientów. Dlatego od początku zapoznajemy się szczegółowo z ich potrzebami i sprawnie na nie odpowiadamy. Jednocześnie świadczymy nasze usługi przy zachowaniu najwyższych standardów bezpieczeństwa - chronimy dane i korespondencję przy pomocy najnowszych technologii, a przy tym dysponujemy dodatkowym ubezpieczeniem.",
    four: "Przy obsłudze naszych Klientów proponujemy stosowanie nowych rozwiązań prawnych oraz korzystamy z innowacji technologicznych dostępnych dla branży prawniczej. Pozwala nam to odpowiednio i sprawnie zaadresować potrzeby naszych Klientów.",
    five: "W każdym czasie nasi Klienci mają możliwość uzyskać informacje na temat swoich spraw i podejmowanych przez nas działań. Gwarantujemy Klientom możliwość otrzymania od nas zestawienia zawierającego opis poszczególnych czynności oraz czas pracy poświęcony na każdą z nich.",
  },
];

function PricingContent() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      {/* Hero unit */}
      <main>
        <NavPc />

        {/* wyjustowac od po czlaej szerokosci, dac ikonki na czarno  */}
        <Container maxWidth="lg">
          <Box
            sx={{
              // backgroundColor: "secondary.main",
              pt: 3,
              pb: 3,
              minHeight: "20vh",
            }}
          >
            <Container
              data-aos="fade-up"
              disableGutters
              component="main"
              sx={{
                pt: 0,
                pb: 0,
                // backgroundColor: "secondary.main"
              }}
            >
              <Container
                sx={{
                  m: 0.2,
                  py: 5,
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "flex",
                    lg: "flex",
                    xl: "flex",
                  },
                }}
              ></Container>

              <Typography
                variant="h5"
                align="justify"
                color=""
                component="p"
                gutterBottom
              >
                <br />
                KMT Kozubek Matusiak. Jesteśmy kancelarią 
                 radców prawnych, która powstała z myślą o kompleksowym
                wsparciu podmiotów gospodarczych w celu umożliwienia im
                skupienia całej swojej uwagi na własnej działalności. Stawiamy
                się w roli partnera prawnego naszego Klienta, starannie
                słuchając jego potrzeb, aby dostarczyć mu najlepszych rozwiązań.
              </Typography>
            </Container>
          </Box>

          <Box
            sx={{
              // backgroundImage: `url(https://images.unsplash.com/photo-1497215728101-856f4ea42174?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80)`,
              // backgroundSize: "cover",
              // backgroundPosition: "center",
              // minHeight: "80vh",
              // alignItems: "center",
              // py: 10,
              // backgroundColor: "secondary.main",
              minHeight: "80vh",
            }}
          >
            <Container
              data-aos="fade-down"
              sx={{
                pt: 5,
                pb: 22,
                // backgroundColor: "secondary.main",
              }}
            >
              <Typography
                component="h1"
                variant="h4"
                align="center"
                color=""
                gutterBottom
                sx={{
                  backgroundColor: "primary",
                }}
              >
                Dlaczego KMT?
                <hr />
              </Typography>
              <Grid container spacing={0} alignItems="">
                {text.map((tier) => (
                  <Grid item key={tier.title} xs={12} md={12}>
                    <Accordion
                      expanded={expanded === "panel1"}
                      onChange={handleChange("panel1")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={tier.one}
                        id={tier.one}
                      >
                        {" "}
                        <ListItemIcon>
                          <List fontSize="large" />
                        </ListItemIcon>
                        <Typography
                          component="h5"
                          variant="h5"
                          align="justify"
                          color=""
                          gutterBottom
                        >
                          Oferujemy kompleksowe usługi
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails align="justify">
                        <Typography>{tier.one}</Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel2"}
                      onChange={handleChange("panel2")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={tier.two}
                        id={tier.two}
                      >
                        {/* <img src={one} width="10%" /> */}
                        <ListItemIcon>
                          <Com fontSize="large" />
                        </ListItemIcon>
                        <Typography
                          component="h5"
                          variant="h5"
                          align="justify"
                          color=""
                          gutterBottom
                        >
                          Stawiamy na komunikację i słuchamy naszych Klientów
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography align="justify">{tier.two}</Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel3"}
                      onChange={handleChange("panel3")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={tier.three}
                        id={tier.three}
                      >
                        <ListItemIcon>
                          <AccessTimeIcon fontSize="large" />
                        </ListItemIcon>
                        <Typography
                          component="h5"
                          variant="h5"
                          align="justify"
                          color=""
                          gutterBottom
                        >
                          Cenimy czas i bezpieczeństwo
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography align="justify">{tier.three}</Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel4"}
                      onChange={handleChange("panel4")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={tier.four}
                        id={tier.four}
                      >
                        <ListItemIcon>
                          <AccessibilityNewIcon fontSize="large" />
                        </ListItemIcon>
                        <Typography
                          component="h5"
                          variant="h5"
                          align="justify"
                          color=""
                          gutterBottom
                        >
                          Jesteśmy otwarci na nowe
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails align="justify">
                        <Typography>{tier.four}</Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel5"}
                      onChange={handleChange("panel5")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={tier.five}
                        id={tier.five}
                      >
                        {" "}
                        <ListItemIcon>
                          <BorderClearIcon fontSize="large" />
                        </ListItemIcon>
                        <Typography
                          component="h5"
                          variant="h5"
                          align="justify"
                          color=""
                          gutterBottom
                        >
                          Nasze usługi są transparentne
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography align="justify">{tier.five}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Box>
          <Helmet>
          <title>O Nas</title>
          <meta name="description" content="O KMT"/>
          <link
            rel="canonical"
            href="http://kmtlegal.pl/ONas"
          />
        </Helmet>
        </Container>
      </main>
    </React.Fragment>
  );
}

export default function Pricing() {
  return <PricingContent />;
}
