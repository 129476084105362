import React, { useEffect } from "react";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AOS from "aos";
import "aos/dist/aos.css";
import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/icons-material/Grading";
import Com from "@mui/icons-material/ConnectWithoutContact";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import BorderClearIcon from "@mui/icons-material/BorderClear";
import NavPc from "./navPcEn";
import { Helmet } from "react-helmet";
const text = [
  {
    one: "The KMT Team consists of experts in various fields of law, enabling us to fully address all of our Clients’ needs and offer them a wide range of legal services. At the same time, at least one of KMT’s partners is directly engaged in every single case. All matters entrusted to us are just as important to us as they are to our Clients.",
    two: "When working with our Clients, we value responsiveness and open communication. We focus on understanding our Clients’ needs and values in order to provide timely and reliable support tailored to individual requirements.    ",
    three:
      "Time and safety of our Clients are our priorities. From the very beginning, we review in detail our Clients’ needs to provide swift support while maintaining the highest safety standards - we keep data and correspondence safe thanks to new technologies, and we also carry additional insurance.       ",
    four: "While providing our services, we are open to new legal solutions and innovative technologies available to lawyers in order to effectively address our Clients’ needs.",
    five: "Our Clients may obtain information about our work on their matters at any time. We provide our Clients with reporting that includes time spent on every single activity.",
  },
];

function PricingContent() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      {/* Hero unit */}
      <main>
        <NavPc />

        {/* wyjustowac od po czlaej szerokosci, dac ikonki na czarno  */}
        <Container maxWidth="lg">
          <Box
            sx={{
              // backgroundColor: "secondary.main",
              pt: 3,
              pb: 3,
              minHeight: "20vh",
            }}
          >
            <Container
              data-aos="fade-up"
              disableGutters
              component="main"
              sx={{
                pt: 0,
                pb: 0,
                // backgroundColor: "secondary.main"
              }}
            >
              <Container
                sx={{
                  m: 0.2,
                  py: 5,
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "flex",
                    lg: "flex",
                    xl: "flex",
                  },
                }}
              ></Container>

              <Typography
                variant="h5"
                align="justify"
                color=""
                component="p"
                gutterBottom
              >
                <br />
                KMT Kozubek Matusiak. We are a law firm created to provide comprehensive business support so that entrepreneurs may focus fully on their own business. We are a trusted legal partner to our Clients, carefully listening to their needs in order to deliver the best solutions.  </Typography>
            </Container>
          </Box>

          <Box
            sx={{
              // backgroundImage: `url(https://images.unsplash.com/photo-1497215728101-856f4ea42174?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80)`,
              // backgroundSize: "cover",
              // backgroundPosition: "center",
              // minHeight: "80vh",
              // alignItems: "center",
              // py: 10,
              // backgroundColor: "secondary.main",
              minHeight: "80vh",
            }}
          >
            <Container
              data-aos="fade-down"
              sx={{
                pt: 5,
                pb: 22,
                // backgroundColor: "secondary.main",
              }}
            >
              <Typography
                component="h1"
                variant="h4"
                align="center"
                color=""
                gutterBottom
                sx={{
                  backgroundColor: "primary",
                }}
              >
                Why KMT?
                <hr />
              </Typography>
              <Grid container spacing={0} alignItems="">
                {text.map((tier) => (
                  <Grid item key={tier.title} xs={12} md={12}>
                    <Accordion
                      expanded={expanded === "panel1"}
                      onChange={handleChange("panel1")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={tier.one}
                        id={tier.one}
                      >
                        {" "}
                        <ListItemIcon>
                          <List fontSize="large" />
                        </ListItemIcon>
                        <Typography
                          component="h5"
                          variant="h5"
                          align="justify"
                          color=""
                          gutterBottom
                        >
                          We offer comprehensive support

                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails align="justify">
                        <Typography>{tier.one}</Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel2"}
                      onChange={handleChange("panel2")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={tier.two}
                        id={tier.two}
                      >
                        {/* <img src={one} width="10%" /> */}
                        <ListItemIcon>
                          <Com fontSize="large" />
                        </ListItemIcon>
                        <Typography
                          component="h5"
                          variant="h5"
                          align="justify"
                          color=""
                          gutterBottom
                        >
We value communication and listening                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography align="justify">{tier.two}</Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel3"}
                      onChange={handleChange("panel3")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={tier.three}
                        id={tier.three}
                      >
                        <ListItemIcon>
                          <AccessTimeIcon fontSize="large" />
                        </ListItemIcon>
                        <Typography
                          component="h5"
                          variant="h5"
                          align="justify"
                          color=""
                          gutterBottom
                        >
We respect time and safety
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography align="justify">{tier.three}</Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel4"}
                      onChange={handleChange("panel4")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={tier.four}
                        id={tier.four}
                      >
                        <ListItemIcon>
                          <AccessibilityNewIcon fontSize="large" />
                        </ListItemIcon>
                        <Typography
                          component="h5"
                          variant="h5"
                          align="justify"
                          color=""
                          gutterBottom
                        >
We are open to the new                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails align="justify">
                        <Typography>{tier.four}</Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel5"}
                      onChange={handleChange("panel5")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={tier.five}
                        id={tier.five}
                      >
                        {" "}
                        <ListItemIcon>
                          <BorderClearIcon fontSize="large" />
                        </ListItemIcon>
                        <Typography
                          component="h5"
                          variant="h5"
                          align="justify"
                          color=""
                          gutterBottom
                        >
Our services are transparent                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography align="justify">{tier.five}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Box>
          <Helmet>
          <title>O Nas</title>
          <meta name="description" content="O KMT"/>
          <link
            rel="canonical"
            href="http://kmtlegal.pl/ONas"
          />
        </Helmet>
        </Container>
      </main>
    </React.Fragment>
  );
}

export default function Pricing() {
  return <PricingContent />;
}
