import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import CardMedia from "@mui/material/CardMedia";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AOS from "aos";
import "aos/dist/aos.css";
import one from "./renders/1.jpeg";
import two from "./renders/1.jpeg";
import three from "./renders/1.jpeg";
import four from "./renders/1.jpeg";
import five from "./renders/1.jpeg";
import ListItemIcon from "@mui/material/ListItemIcon";
import ContentCut from "@mui/icons-material/ContentCut";
import NavPc from "./navPcEn";
import Jakub from "./renders/Jakub.webp";
import Jan from "./renders/Jan.webp";
import Maciej from "./renders/Jan.webp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import {Helmet} from "react-helmet";

const text = {
  pic: <CardMedia
  component="img"
  alt="no img"
  width="100%"
  image={Jan}
  sx={{borderRadius:5,
  }}
/>,   title: " Jan Matusiak",
  parg1: "Attorney-at-law and KMT Managing Partner.  ",
  parg2: "Expert in civil and corporate law, with particular emphasis on procedural issues, corporate law and bankruptcy matters.",
  parg3: "He has several years of experience in providing legal services to entrepreneurs as well as individuals. Before founding KMT, he gained his professional experience at prestigious law firms in Kraków. As part of his extensive litigation practice, he dealt with issues of banking law, in particular representing borrowers in Swiss franc trials. He also participated in a number of bankruptcy proceedings of complex nature, representing both insolvent entities and creditors. He is experienced in supporting business entities, including their reorganization, transformation and restructuring in healthcare, IT and construction sectors, among others. Additionally, he represented domestic and foreign new technology businesses, including a provider of blockchain technology.",
  parg4: "He authored many analyses and legal opinions, for example on the determination of real estate legal status, including under provisions of the compensation agreement between Poland and Switzerland as well as on the consequences of mergers, divisions and transformations of business entities from the perspective of new tax regulations ( “New Polish Deal”).",
  parg5: "Graduate of the Faculty of Law and Administration of the Jagiellonian University in Kraków. Member of the Kraków Bar Association of Attorneys-at-Law.",
  parg6: <p> 
  <br/>He is fluent in legal English, allowing him to effectively provide legal services to foreign clients. </p>,
  parg7:"Outside of work, he is an enthusiast of historical literature.",
  buttonText: "+48690720320",
  buttonVariant: "outlined",
  buttonText2: "j.matusiak@kmtlegal.pl",
  buttonVariant2: "outlined",
  buttonText3: "LinkedIn",
  buttonVariant3: "outlined",
};



// zmienic na czarny otline przyciskow
function PricingContent() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <NavPc/>
      <Grid container spacing={0} alignItems="">
      <Grid item key="about" xs={0} md={0} lg={3}>
      <Card
            data-aos="fade-right"
            sx={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              minHeight: "101vh",
              alignItems: "justify",
              py: 5,
            }}
          >
           
       
          </Card>
        </Grid>
        <Grid item key="about" xs={12} md={3} lg={2}>
          <Card
            data-aos="fade-right"
            sx={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              minHeight: "101vh",
              alignItems: "justify",
              py: 5,
            }}
          >
               {text.pic}
           
            <CardContent>
            <Box
                sx={{
                  justifyContent: "center",
                  alignItems: "baseline",
                  mb: 2,
                }}
              >
                <Typography
                  component="h4"
                  variant="h4"
                  align="center"
                  color="text.third"
                   
                > 
                  {text.title}
                </Typography>
                <br />
               <Button href="tel:+48690720320" fullWidth color="third" variant={text.buttonVariant}>
                  {text.buttonText}
                </Button>
                <br /><br />
                <Button href="mailto:j.matusiak@kmtlegal.pl" fullWidth color="third"  variant={text.buttonVariant2}>
                  {text.buttonText2}
                </Button>
                <br /><br />
                <Button href="https://www.linkedin.com/in/jan-matusiak-000842183/?originalSubdomain=pl" fullWidth color="third"  variant={text.buttonVariant2}>
               {text.buttonText3} <LinkedInIcon />
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item key="bio" xs={12} md={9} lg={7}>
          <Card
            data-aos="fade-justify"
            sx={{
              minHeight: "101vh",

              py: 5,
            }}
          >
            <CardContent>
              <Box
                sx={{
                  justifyContent: "justify",
                  maxWidth: "sm",
                  mb: 2,
                  
                }}
              >
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                   
                >
                  {text.parg1}<br/><br/>
                </Typography>
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                   
                >
                  {text.parg2}<br/><br/>
                </Typography>
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                   
                >
                  {text.parg3}<br/><br/>
                </Typography>
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                  gutterBottom
                >
                  {text.parg4}<br/><br/>
                </Typography>
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                   
                >
                  {text.parg5}<br/><br/>
                </Typography>
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                   
                >
                  {text.parg6}<br/>
                </Typography>
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                   
                >
                  {text.parg7}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Helmet>
        
        <title>
        Jan Matusiak 
        </title>
        <meta name="description" content="Kancelaria KMT Jan Matusiak  obsuga prawna przedsiębiorców, podmiotów gospodarczych i indywidualnych klientów"
        />
            <meta name="keywords" content="KMT, Kozubek Matusiak  Radcowie Prawni spółka cywilna, KMT Kozubek Matusiak , KMT Legal, KMT Kozubek Matusiak 
    Jan Matusiak, KMT,  Obsługa prawna przedsiębiorców w Krakowie, Prawnicy w Krakowie, Radcowie prawni w Krakowie, Jan Matusiak"/>
        <link rel="canonical" href="https://kmtlegal.pl/Jan%20Matusiak"/>
      </Helmet>
      </Grid>
    </React.Fragment>
  );
}

export default function Pricing() {
  return <PricingContent />;
}
