import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import CardMedia from "@mui/material/CardMedia";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AOS from "aos";
import "aos/dist/aos.css";
import one from "./renders/1.jpeg";
import two from "./renders/1.jpeg";
import three from "./renders/1.jpeg";
import four from "./renders/1.jpeg";
import five from "./renders/1.jpeg";
import ListItemIcon from "@mui/material/ListItemIcon";
import ContentCut from "@mui/icons-material/ContentCut";
import NavPc from "./navPc";
import Jakub from "./renders/Jakub.webp";
import Jan from "./renders/Jan.webp";
import Maciej from "./renders/Daria.webp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import {Helmet} from "react-helmet";

const text = {
  pic: <CardMedia
  component="img"
  alt="no img"
  width="100%"
  image={Maciej}
  sx={{borderRadius:5,
  }}
/>,
  
  title: " Daria Meysztowicz",
  parg1: "Attorney-at-law.",
  parg2: "Expert in providing comprehensive transactional support, especially in venture capital investments, as well as in advising Clients on their business activities.  ",
  parg3: "She has extensive experience in negotiating SHAs/SPAs and conducting due diligence. She also took part in various transactions involving international investment funds and business angels at different stages of financing (from pre-seed to Series A), which enables her to provide practical advice to Clients on strategic solutions to achieve transaction goals.",
  parg4: "Graduate of both the Faculty of Law and Administration and French Studies of the Jagiellonian University in Kraków.",
  parg5: "She is fluent in English, French and Spanish.",
  buttonText: "+48690720320",
  buttonVariant: "outlined",
  buttonText2: "d.meysztowicz@kmtlegal.pl",
  buttonVariant2: "outlined",
  buttonText3: "LinkedIn",
  buttonVariant3: "outlined",
};
// zmienic na czarny otline przyciskow
function PricingContent() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <NavPc/>
      <Grid container spacing={0} alignItems="">
      <Grid item key="about" xs={0} md={0} lg={3}>
          <Card
            data-aos="fade-right"
            sx={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              minHeight: "101vh",
              alignItems: "justify",
            }}
          >
         
           
          </Card>
        </Grid>
        <Grid item key="about" xs={12} md={3} lg={2}>
          <Card
            data-aos="fade-right"
            sx={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              minHeight: "101vh",
              alignItems: "justify",
              py: 5,
            }}
          >
           
           {text.pic}
            <CardContent>
            <Box
                sx={{
                  justifyContent: "center",
                  alignItems: "baseline",
                  mb: 2,
                }}
              >
                <Typography
                  component="h4"
                  variant="h4"
                  align="center"
                  color="text.third"
                  gutterBottom
                > 
                  {text.title}
                </Typography>
                <br />
               <Button href="tel:+48690720320" fullWidth color="third" variant={text.buttonVariant}>
                  {text.buttonText}
                </Button>
                <br /><br />
                <Button href="mailto:d.meysztowicz@kmtlegal.pl" fullWidth color="third"  variant={text.buttonVariant2}>
                  {text.buttonText2}
                </Button>
                <br /><br />
                <Button href="https://www.linkedin.com/in/daria-meysztowicz-a15215173/?originalSubdomain=pl" fullWidth color="third"  variant={text.buttonVariant2}>
               {text.buttonText3} <LinkedInIcon />
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item key="bio" xs={12} md={9} lg={7}>
          <Card
            data-aos="fade-justify"
            sx={{
              minHeight: "101vh",

              py: 5,
            }}
          >
            <CardContent>
              <Box
                sx={{
                  justifyContent: "justify",
                  maxWidth: "sm",
                  mb: 2,
                  
                }}
              >
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                  gutterBottom
                >
                  {text.parg1}<br/><br/>
                </Typography>
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                  gutterBottom
                >
                  {text.parg2}<br/><br/>
                </Typography>
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                  gutterBottom
                >
                  {text.parg3}<br/><br/>
                </Typography>
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                  gutterBottom
                >
                  {text.parg4}<br/><br/>
                </Typography>
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                  gutterBottom
                >
                  {text.parg5}<br/><br/>
                </Typography>
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                  gutterBottom
                >
                  {text.parg6}<br/><br/>
                </Typography>
                <Typography
                  variant="h7"
                  align="justify"
                  color=" text.third"
                  component="p"
                  gutterBottom
                >
                  {text.parg7}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Helmet>
        
        <title>
        Daria Meysztowicz 
        </title>
        <meta name="description" content="Kancelaria KMT Kozubek Matusiak  obsuga prawna przedsiębiorców, podmiotów gospodarczych i indywidualnych klientów"
        />
        <link rel="canonical" href="https://kmtlegal.pl/Daria%20Meysztowicz"/>
      </Helmet>
      </Grid>
    </React.Fragment>
  );
}

export default function Pricing() {
  return <PricingContent />;
}
