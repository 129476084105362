import * as React from "react";
import "./App.css";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { grey, teal } from "@mui/material/colors";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from "./src/Contact";
import Zespol from "./src/Zespol";
import Home from "./src/Home";
import Onas from "./src/Onas";
import Blog from "./src/Blog";
import Jan from "./src/Jan";
import Maciej from "./src/Maciej";
import Jakub from "./src/Jakub";
import Specjalizacje from "./src/Specjalizacje";
import emailjs from "@emailjs/browser";
import Cookies from "./src/cookies.jsx";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';

import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import InfoIcon from '@mui/icons-material/Info';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import logo from "./src/svg/kmt-z-zielony-czerwony.svg";
import Daria from "./src/Daria";
import Waclaw from "./src/Waclaw";
import AML from "./src/aml.jsx";
import DodajPost from "./src/DodajPost.jsx";
import Post from "./src/Post";
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import DocumentMeta from "react-document-meta";
import Footer from "./src/Footer";

import ContactEn from "./src/ContactEn";
import ZespolEn from "./src/ZespolEn";
import HomeEn from "./src/HomeEn";
import OnasEn from "./src/OnasEn";
import BlogEn from "./src/BlogEn";
import JanEn from "./src/JanEn";
import MaciejEn from "./src/MaciejEn";
import JakubEn from "./src/JakubEn";
import SpecjalizacjeEn from "./src/SpecjalizacjeEn";
import DariaEn from "./src/DariaEn";
import WaclawEn from "./src/WaclawEn";
import PostEn from "./src/PostEn";
import FooterEn from "./src/FooterEn";

import { Language } from "@mui/icons-material";


function Copyright() {
  return (
    <Typography variant="body2" color="white" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://cholujdesign.com/">
        CholujDesign S.C.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
function sendEmail(e) {
  e.preventDefault();

  emailjs
    .sendForm(
      "service_a1wwmva",
      "template_jnm4g0d",
      e.target,
      "user_x9kPpW1JmhYVbwVWmxAVD"
    )
    .then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
}

const theme = createTheme({
  palette: {
    primary: {
      light: "#825b07",
      main: "#825b07",
      dark: "#825b07",
      contrastText: "#000",
    },
    secondary: {
      light: "#022911",
      main: "#022911",
      dark: "#022911",
      contrastText: "#fff",
    },
    third: {
      light: "#000000",
      main: "#000000",
      dark: "#000000",
      contrastText: "#000000",
    },
  },
  shadows: ["none"],
  typography: {
    fontFamily: ["Lato"].join(","),
    textTransform: 'none', button: {
      textTransform: 'none'
    }
  },
});
const meta = {
  title: "kmtlegal",
  description: "kancelaria kmtlegal",
  canonical: "https://kmtlegal.netlify.app",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "kancelaria, krakow, prawnik",
    },
  },
};
export default function App() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  

 
    return (
      <ThemeProvider theme={theme}>
        <DocumentMeta {...meta}>
  
        <div className="App">
          <CssBaseline />
       
          
            <main>
          <BrowserRouter>
            <Routes>
              <Route path="/HomeEn" element={<HomeEn />} />
              <Route path="/KontaktEn" element={<ContactEn />} />
              <Route path="/AboutEn" element={<OnasEn />} />
              <Route path="/AktualnosciEn" element={<BlogEn />} />
              <Route path="/KMTEn" element={<ZespolEn />} />
              <Route path="/Jan MatusiakEn" element={<JanEn />} />
              <Route path="/Jakub KozubekEn" element={<JakubEn />} />
              <Route path="/Maciej TruszkiewiczEn" element={<HomeEn />} />
              <Route path="/Daria MeysztowiczEn" element={<DariaEn />} />
              <Route path="/Waclaw ChmielEn" element={<WaclawEn />} />
              <Route path="/SpecjalizacjeEn" element={<SpecjalizacjeEn />} />
              <Route path="/cookiesEn" element={<Cookies />} />
              <Route path="/zmianyEn" element={<DodajPost />} />
              <Route path="/AML-RODO" element={<AML/>} />
              <Route path="/AktualnosciEn" element={<BlogEn/>} />
              {/* <Route path="/Aktualnosci/:Post" element={<PostEn/>} /> */}

              <Route path="/home" element={<Home />} />
                <Route path="/Kontakt" element={<Contact />} />
                <Route path="/About" element={<Onas />} />
                <Route path="/Aktualnosci" element={<Blog />} />
                <Route path="/KMT" element={<Zespol />} />
                <Route path="/Jan Matusiak" element={<Jan />} />
                <Route path="/Jakub Kozubek" element={<Jakub />} />
                <Route path="/Maciej Truszkiewicz" element={<Home />} />
                <Route path="/Daria Meysztowicz" element={<Daria />} />
                <Route path="/Waclaw Chmiel" element={<Waclaw />} />
                <Route path="/Specjalizacje" element={<Specjalizacje />} />
                <Route path="/cookies" element={<Cookies />} />
                <Route path="/zmiany" element={<DodajPost />} />
                <Route path="/AML-RODO" element={<AML/>} />
                <Route path="/Aktualnosci" element={<Blog/>} />
                <Route path="/Aktualnosci/:Post" element={<Post/>} />
                

              <Route path="/:any" element={<Home />} />
              <Route path="/" element={<Home />} />
            </Routes>
          </BrowserRouter>
        </main>
          {/* Footer */}
          <Footer/>
          {/* End footer */}
          <h1> KMT Kozubek Matusiak </h1>
        </div></DocumentMeta>
      </ThemeProvider>
    );}

