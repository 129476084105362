import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import GlobalStyles from "@mui/material/GlobalStyles";
import CssBaseline from "@mui/material/CssBaseline";
import Realestate from "@mui/icons-material/AccountBalance";
import Employer from "@mui/icons-material/FollowTheSigns";
import Employee from "@mui/icons-material/SupportAgent";
import Court from "@mui/icons-material/Gavel";
import Spolka from "@mui/icons-material/Handshake";
import Transaction from "@mui/icons-material/Receipt";
import Upadlosc from "@mui/icons-material/MoneyOff";
import It from "@mui/icons-material/Computer";
import PhoneIcon from "@mui/icons-material/AssuredWorkload";
import { Button, CardActionArea, CardActions } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import NavPc from "./navPcEn";
import AOS from "aos";
import "aos/dist/aos.css";
import {Helmet} from "react-helmet";
const one = [
  {
    pic: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80",

    tab: "Legal Services for Entrepreneurs",
    index: 0,
    icon: <Employer />,
    bgPic:
      "https://images.unsplash.com/photo-1541746972996-4e0b0f43e02a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    parg1: "Legal Services for Entrepreneurs",
    parg2: (
      <p>
        Every day, entrepreneurs deal with many challenges, including legal ones. Legal Services for Entrepreneurs include legal support of day-to-day business activities, regardless of their form, size and stage, including any activity that is not only necessary, but also desired by an entrepreneur. This practice consists primarily of agreement negotiations, support of an entrepreneur’s bodies, adoption of resolutions, supervision of employment matters, and support in organizing and funding new entities (start-ups).
      </p>
    ),
    parg3: "",
    parg4: "Our “Legal Services for Entrepreneurs” practice includes, but are not limited to, the following services:",
    parg5: [
      <ul>
        <li> •
        legal advice to entrepreneurs on legal aspects of their business (ongoing legal advice);        </li>
        <li>
          <br /> •
          monitoring of changes in law to adjust the Client’s business to new provisions of law;
        </li>
        <li>
          <br />
          • Client support in debt recovery processes;
        </li>
        <li>
          <br />
          • 
          employee matters supervision;
        </li>
        <li>
          <br />
          • 
          formation of company bodies and implementation of changes in their composition;
        </li>
        <li>
          <br />
          • 
          analysis of and advice on an entrepreneur’s organizational structure;

        </li>
        <li>
          <br />
          • 
          support for entrepreneurs in compliance with legal requirements (for example in the process of registration in the Central Register of Beneficial Owners);
  </li>
        <li>
          {" "}
          <br />
          • 
          drafting business entity resolutions, such as introducing changes in the certificates of incorporation and share capital increase or decrease;
   </li>{" "}
        <li>
          {" "}
          <br />
          • 
          Client representation in court and in dealing with public authorities, including in registry courts as well as in disputes between shareholders;
        </li>{" "}
        <li>
          {" "}
          <br />
          • 
          Client representation during shareholder and other meetings.</li>
      </ul>,
    ],
    parg6: [
      <ul>
        <li>
          <br />• 
          open and transparent communication with the Client, focused on using terms understandable to entrepreneurs as well as on understanding and fulfilling Client needs;      </li>
        <li>
          <br />• 
          extensive experience in ongoing legal services for entrepreneurs in various sectors, such as investments, healthcare, FMCG, IT, manufacturing, real estate, e-commerce, biotechnology and energy;
        </li>
        <li>
          <br />• 
          experience in non-legal business, which allows KMT to understand the Client’s business needs;
        </li>
        <li>
          <br />• 
          well-rounded and specialized team with knowledge in different fields of law, enabling KMT to provide comprehensive legal services.
        </li>
      </ul>,
    ],

    buttonText: "Telefon",
    buttonVariant: "outlined",
    buttonText2: "Email",
    buttonVariant2: "outlined",
    buttonText3: "LinkedIn",
    buttonVariant3: "outlined",
  },
  {
    pic: "4528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80",

    tab: "Litigation",
    index: 1,
    icon: <Court />,
    bgPic:
      "https://images.unsplash.com/photo-1539190346343-2063b7a88208?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1753&q=80",
    parg1: "Litigation",
    parg2: (
      <p>
       Every person, especially an entrepreneur, may find themselves in situations that require involvement of appropriate legal institutions. While some cases may be resolved through negotiations between the parties, others require a finding of a court or another legal institution.
      </p>
    ),
    parg3: "",
    parg4: "Our “Litigation” practice includes, but is not limited to, the following services:    ",
    parg5: [
      <ul>
        <li>
          <br />• 
          Client representation in civil, family or commercial disputes both in common courts and the Supreme Court;        </li>
        <li>
          <br />• 
          Client representation during pre-litigation stage, intended to settle a dispute without court intervention, for example by negotiation;  </li>
        <li>
          <br />• 
          drafting pleadings at every stage of the proceedings;
        </li>
        <li>
          <br />• 
          Client representation in ADR (Alternative Dispute Resolution) proceedings, for example in mediation or arbitration;
        </li>
        <li>
          <br />• 
          litigation strategy development to mitigate risks and costs on the part of the Client as well as to maximize chances of a positive outcome;
        </li>
        <li>
          <br />
          • 
          pre-litigation legal analysis to determine the grounds for legal actions to allow the Client to make a conscious decision on making claims; </li>
        <li>
          <br />• 
          Client representation in enforcement proceedings, both in front of a bailiff and in court;
        </li>
        <li>
          <br />• 
          legal opinions on matters to be resolved by a court or a public authority;        </li>
        <li>
          <br />• 
          Client representation in dealing with public authorities and administrative courts, including the Supreme Administrative Court. </li>
      </ul>,
    ],
    parg6: [
      <ul>
        <li>
          <br /> • 
          long experience in litigation, gained at the most prestigious law firms;
        </li>
        <li>
          <br />• 
          well-rounded and specialized team with knowledge in various fields of law related to provisions applicable in a given case;
        </li>
        <li>
          <br />• 
          support of experts outside the area of legal advice;        </li>
        <li>
          <br />• 
          comprehensive approach to any case that covers, among other areas, analysis of individual Client situation and needs as well as development of a litigation strategy to maximize chances of a positive outcome;   </li>
        <li>
          <br />• 
          open and transparent communication with the Client to allow the Client to understand legal issues that are the subject of litigation as well as to obtain necessary information and documentation.  </li>
      </ul>,
    ],

    buttonText: "Telefon",
    buttonVariant: "outlined",
    buttonText2: "Email",
    buttonVariant2: "outlined",
    buttonText3: "LinkedIn",
    buttonVariant3: "outlined",
  },
  {
    pic: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80",
    title: "  ",
    tab: "Corporate Law",
    index: 2,
    icon: <Spolka />,
    bgPic:
      "https://images.unsplash.com/photo-1549923746-c502d488b3ea?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80",
    parg1: "Corporate Law ",
    parg2:
      <p>Economic and legal changes require continuous adjustments to new conditions. The rapid pace of these changes creates increasingly more opportunities for entrepreneurs.
      </p>,
    parg3:'',
    parg4: "Our “Corporate law” practice includes, but is not limited to, the following services:  ",
    parg5: [
      <ul>
        <li>
          <br />•ormation of companies, such as limited liability companies (LLC; sp. z o.o.), joint stock companies/incorporated (Inc.; S.A.) and simple joint stock companies (P.S.A.);
        </li>
        <li>
          <br />• formation of partnerships, such as general partnerships (sp. j.), professional partnerships (sp. p.), limited partnerships (sp. k.) and partnerships limited by shares (S.K.A.);
        </li>
        <li>
          <br />• legal advice on formation of “investment vehicles” such as investment funds and alternative investment funds;
        </li>
        <li>
          <br />• formation of company affiliates in Poland and abroad;
        </li>
        <li>
          <br />• legal advice on structuring a group of companies;
        </li>
        <li>
          <br />• legal advice on formation, organization and structure of undertakings intended to achieve a specific goal, for example joint ventures;
        </li>
        <li>
          <br />• drafting and negotiating certificates of incorporation given the nature and goals of the forming entity;
        </li>
        <li>
          <br />• drafting full documentation necessary to register a company.
        </li>
       
      </ul>,
    ],
    parg6: [
      <ul>
        <li>
          <br />• pro-business approach manifested in analysing Client needs and choosing solutions suited to their individual situation;
        </li>
        <li>
          <br />• vast experience in the field of broadly defined corporate law, both in corporate affairs and corporate disputes;
        </li>
        <li>
          <br />• well-rounded and specialized team with experience in providing legal services for investment funds and in running own non-legal businesses;
        </li>
        <li>
          <br />• attention to capital markets and continuous knowledge expansion in this field along with ongoing monitoring of trends in law and economics;
        </li>
        <li>
          <br />• open and transparent communication with the Client, using terms understandable to entrepreneurs.
        </li>
      </ul>,
    ],

    buttonText: "Telefon",
    buttonVariant: "outlined",
    buttonText2: "Email",
    buttonVariant2: "outlined",
    buttonText3: "LinkedIn",
    buttonVariant3: "outlined",
  },
  {
    pic: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80",

    tab: "Mergers & Acquisitions (M&A) / Investments ",
    index: 3,
    icon: <Transaction />,
    bgPic:
      "https://images.unsplash.com/photo-1541746972996-4e0b0f43e02a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    parg1: "Mergers & Acquisitions (M&A) / Investments  ",
    parg2: (
      <p>
       “Mergers & Acquisitions (M&A) / Investments” is one of the many ways of enterprise development, which may come in the form of a merger (when two or more entities merge into one) or an acquisition (when one entity acquires control over another). The purpose of the transaction is usually to achieve synergy effect, i.e., greater value of the combined enterprise than the sum of the parts.

      </p>
    ),
    parg3:
      <p>Within investment processes, one entity (an investor, for example a venture capital/private equity fund, an alternative investment fund or a business angel) decides to invest in another entity (a target, such as a start-up) to help grow the target and generate a profit. The investment may take various forms, for example investing in an existing entity or a new one formed for a specific purpose.</p>,
    parg4: (
      <p>
       Our “Mergers & Acquisitions (M&A) / Investments” practice includes, but is not limited to, the following services:
      </p>
    ),
    parg5: [
      <ul>
        <li>
          • comprehensive legal advice on the investment process for investors (including venture capital/private equity funds or business angels) as well as for targets and their current owners (shareholders, including founders of start-ups);
        </li>
        <li>
          <br />• comprehensive legal advice on M&A transactions for acquirers, targets and merger parties;
        </li>
        <li>
          <br />• drafting and negotiating of non-disclosure agreements (NDAs);
        </li>
        <li>
          <br />• managing and conducting due diligence processes (buy-side and vendor);

        </li>
        <li>
          <br />• drafting and negotiating of Share Purchase Agreements/Sale and Purchase Agreements (SPAs);
        </li>
        <li>
          <br />• drafting and negotiating of Shareholders Agreements (SHAs);
        </li>
        <li>
          <br />• management of and legal advice on target share capital increase or decrease as well as drafting of applicable documents;
        </li>
        <li>
          <br />• support of buyers and sellers in the process of satisfying conditions of the transaction (both precedent and subsequent);
        </li>
        <li>
          <br />• support of buyers in the process of post-merger integration;
        </li>
        <li>
          <br />• drafting and negotiating agreements to obtain funding for planned investments;
        </li>
        <li>
          <br />• analysis of planned investments from the perspective of compliance law, including anti-monopoly provisions as well as Client representation in dealing with relevant public authorities;

        </li>
        <li>
          <br />• development and implementation of a strategy against hostile takeovers.
        </li>
      </ul>,
    ],
    parg6: [
      <ul>
        <li>
          <br />• well-rounded and specialized team with skills to provide comprehensive services in a transaction process;

        </li>
        <li>
          <br />• experience gained during many transaction processes, representing investors (including venture capital/private equity funds and alternative investment funds), targets and their owners (including founders of start-ups);
        </li>
        <li>
          <br />• competence of our experts who expand their skills and knowledge in this matter continuously, both from a legal and a business standpoint;
        </li>
        <li>
          <br />• individual and practical approach to the investment process, recognizing Client needs and interests as well as the nature of businesses involved in the transaction;
        </li>
        <li>
          <br />• open and transparent communication to facilitate an efficient investment process;

        </li>
        <li>
          <br />• pro-investment approach to facilitate an investment agreement between the counterparties.
        </li>
      </ul>,
    ],

    buttonText: "Telefon",
    buttonVariant: "outlined",
    buttonText2: "Email",
    buttonVariant2: "outlined",
    buttonText3: "LinkedIn",
    buttonVariant3: "outlined",
  },
  {
    pic: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80",
    title: "  ",
    tab: "IT / New Technologies",
    icon: <It />,
    index: 4,
    bgPic:
      "https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1772&q=80",
    parg1: "IT / New Technologies ",
    parg2: (
      <p>
Nowadays, every business interacts with the IT / New Technologies sector. Therefore, legal matters associated with this area become increasingly more relevant and require attention of all entrepreneurs, especially those who want to develop their businesses using new technologies. 
      </p>
    ),
    parg3: (
      <p>
        Our “IT / New Technologies” practice covers all legal matters that arise during formation, organization and ongoing activities of business and are related to using IT / New Technologies solutions. For example, the practice covers issues of data protection rights (e.g., collected through business activity on the Internet) or intellectual property rights (e.g., determination whether the copyrights are owned by an author or by the ordering party).

      </p>
    ),
    parg4: <p>Our “IT / New Technologies” practice includes, but is not limited to, the following services:</p>,
    parg5: [
      <ul><li>•
      legal advice on the formation and organization of IT / New technologies businesses, such as choosing the form of business activity or shareholders agreement;
      </li><li><br/>•
      
      support in the process of funding, including drafting and negotiating investment agreements with potential investors or providing legal advice during the process of obtaining funding;
      </li><li><br/>• drafting and negotiating agreements regulating intellectual property rights, for example copyrights agreements;
      </li><li><br/>• support in the protection of company secrets, know-how and personal rights;
      </li><li><br/>• development of necessary statutes and by-laws associated with conducting business using new technologies;
      </li><li><br/>• development and introduction of procedures to achieve the required level of protection of intellectual property rights, personal data and personal rights;
      </li><li><br/>• Client representation in civil and administrative cases associated with IT, intellectual property rights, protection of personal data and protection of personal rights, including development of a litigation strategy, negotiation, drafting and negotiating settlements as well as representing a party in court or in dealing with public authorities;
      </li><li><br/>• analysis of compliance of business activities with laws governing intellectual property rights or personal data protection;
      </li><li><br/>• support in introducing new opportunities in the IT sector (for example IP Box).</li></ul>
        ],
    parg6: [
      <ul><li>• young team interested in new technologies and IT solutions, with skills and knowledge in various fields of law, enabling us to provide comprehensive services for these sectors;
      </li><li><br/>• experience gained during investment processes and providing legal services for start-ups;
      </li><li><br/>• openness to new technological solutions and analysis thereof under provisions of law;
      </li><li><br/>• creative approach to individual Client problems to achieve the expected outcome;
      </li><li><br/>• open and transparent communication, recognizing and addressing Client needs.</li></ul>   ],

    buttonText: "Telefon",
    buttonVariant: "outlined",
    buttonText2: "Email",
    buttonVariant2: "outlined",
    buttonText3: "LinkedIn",
    buttonVariant3: "outlined",
  },
  {
    pic: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80",
    title: "  ",
    tab: "Real Estate",
    index: 5,
    icon: <Realestate />,
    bgPic:
      "https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    parg1: "Real Estate",
    parg2: (
      <p>
     “Real Estate” covers three principal areas of activity.
 <br />
        <br />
        The first area covers the investment process, i.e., a situation in which a given entity (investor) invests in a property in order to construct or modernize a construction object, which may require legal support, including drafting and negotiating investment agreements, conducting real estate due diligence
        <br />
        <br />
        The second area covers ongoing services for the real estate industry and its stakeholders. Within this area, necessary legal support includes drafting and negotiating contracts related to the transfer of real estate ownership (for example a sale contract, a development contract) or its use (for example tenancy, lease) as well as representing a party in dealing with authorities and courts or in other areas of day-to-day Client a
        <br />
        <br />
        The third area covers litigation related to real estate matters, including legal support in seeking compensation for damage resulting from breach of contract, physical or legal defects of real estate, as well as in determining legal status of real estate.
      </p>
    ),
    parg3: "",
    parg4: <p>Our “Real Estate” practice includes, but is not limited to, the following services: 
    </p>,
    parg5: [
      <ul><li>• comprehensive advice for investors and real estate developers at every stage of the construction and investment process, including analysing legal status of real estate (real estate due diligence) in order to propose an appropriate strategy and available investment solutions to purchase or sell real estate;
      </li><li><br/>• drafting and negotiating agreements regulating the investment process (investment agreements, cooperation agreements, shareholders agreements) as well as advice on choosing the form of business for the investment;        
      </li><li><br/>• drafting and negotiating real estate agreements, in particular contracts for sale of the real estate, contracts for lease or tenancy, development agreements, construction works agreements and contracts with a general contractor or subcontractors;
      </li><li><br/>• representation in courts and in dealing with public authorities in real estate cases, including the construction process, in particular to obtain decisions and legal permits necessary to carry out the construction project (e.g. zoning permits, building permits, environmental permits);
      </li><li><br/>• representation in real estate cases for damages, in particular in connection with breach of real estate contracts;
      </li><li><br/>• support in obtaining financing for investments, including drafting and negotiating loan agreements between an investor and a bank;
      </li><li><br/>• support in the process of determining legal status of real estate, including representation in cases related to prescription, expropriation or dissolution of co-ownership of real estate.</li></ul> ],
    parg6: [
      <ul><li>• well-rounded and specialized team with knowledge in both real estate law and other areas of law which is necessary to provide comprehensive support to our Clients in the real estate market;
      </li><li><br/>• support of experts from outside the area of legal advice;
      </li><li><br/>• robust experience in investment processes and disputes related to real estate;
      </li><li><br/>• individual and practical approach to each case, e.g., by conducting an analysis of the factual and legal status of a property and adapting the actions to individual Client situation.</li></ul>
      ,
    ],

    buttonText: "Telefon",
    buttonVariant: "outlined",
    buttonText2: "Email",
    buttonVariant2: "outlined",
    buttonText3: "LinkedIn",
    buttonVariant3: "outlined",
  },
  {
    pic: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80",

    tab: "Insolvency & Restructuring",
    index: 6,
    icon: <Upadlosc />,
    bgPic:
      "https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80",
    parg1: "Insolvency & Restructuring",
    parg2:
    <p>“Bankruptcy and Restructuring” includes services addressed to debtors and creditors aimed at solving financial issues related to insolvency and debt relief. Provided that the proceeding is conducted properly, an interested party may free itself from or significantly reduce their debt burden. At the same time, if prerequisites of restructuring are satisfied, it is possible to eliminate financial problems while maintaining the enterprise and its operation.</p>,
    parg4:
     "Our “Bankruptcy and Restructuring” practice includes, but is not limited to, the following services:      ",
    parg5: [
      <ul><li>• legal advice to debtors, including preparing an analysis of the most optimal solutions involving the choice between bankruptcy and restructuring proceedings, and various ways to carry them out;
</li><li><br/>• legal advice to creditors, presenting solutions to determine debtor assets and conducting effective enforcement thereof;
</li><li><br/>• participation in negotiations on the part of debtors and creditors;
</li><li><br/>• drafting bankruptcy petitions for entrepreneurs;
drafting bankruptcy petitions for natural persons (consumer bankruptcy);
</li><li><br/>• representation in court in bankruptcy proceedings, including supporting the Client during creditor’s committee meetings, at the stage of receiver’s reports and in the distribution of funds of the bankruptcy estate;
</li><li><br/>• support for entrepreneurs in the restructuring process;
</li><li><br/>• legal services for receivers, in particular in cases concerning the bankruptcy estate, including actio pauliana;
</li><li><br/>• legal advice for members of governing bodies of entities threatened with insolvency, in particular on their liability for debts of an insolvent enterprise;
</li><li><br/>• legal advice for third parties (e.g., guarantors) on debt liability;
</li><li><br/>• legal advice in the process of prepared liquidation (pre-pack).</li></ul>
  ],
    parg6: [
      <ul><li>• extensive experience in insolvency matters, in particular in bankruptcy and restructuring proceedings;
      </li><li><br/>• continuous knowledge development related to bankruptcy and restructuring law;
      </li><li><br/>• ongoing support of a restructuring advisor in order to solve the most complex cases;
      </li><li><br/>• individual approach to each case in order to determine the best solution for the Client and consequences of the proposed actions, using extensive knowledge in various areas of law relevant to bankruptcy/restructuring proceedings.</li></ul>,
    ],

    buttonText: "Telefon",
    buttonVariant: "outlined",
    buttonText2: "Email",
    buttonVariant2: "outlined",
    buttonText3: "LinkedIn",
    buttonVariant3: "outlined",
  },
  {
    pic: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80",
    title: "  ",
    tab: "Labor Law",
    index: 7,
    icon: <Employee />,
    bgPic:
      "https://images.unsplash.com/photo-1527192491265-7e15c55b1ed2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    parg1: "Labor Law ",
    parg2:
    <p>New regulations of the employer-employee relationship and dynamic changes in the labour market become a real challenge for employers. Labour law applies to every entrepreneur that hires employees. It regulates relations between employers and their employees as well as in a workplace (for example home office regulations). In today’s world, both national and EU legislators focus on employee rights. In addition, it is becoming increasingly more difficult to attract and keep specialist workers. A well-constructed management or employment contract helps in maintaining a team of specialists in a workplace. Monitoring compliance of activities with labour law allows a company to save time and resources as well as minimize the risk of employee conflicts.</p>,
    parg3:"",
    parg4: "Our “Labour Law” practice includes, but is not limited to, the following services: ",
    parg5: [
      <ul><li>• drafting and negotiating employment and civil law contracts, including mandate, contracts for services, B2B contracts and management contracts;
      </li><li><br/>• drafting and negotiating agreements securing parties’ interests, including non-disclosure agreements (NDAs) and non-compete agreements;
      </li><li><br/>• drafting internal legal acts, including workplace regulations (for example home office regulations) or anti-discrimination and anti-mobbing policies;
      </li><li><br/>• representation of employers, employees and trade unions in court and in dealing with public authorities, including the National Labour Inspectorate; 
      </li><li><br/>• support in communication and negotiations between employers, employees and trade unions;
      </li><li><br/>• drafting collective agreements;
      </li><li><br/>• support in the process of obtaining legal residence and work in Poland;
      </li><li><br/>• support in the process of employee leasing and outsourcing;
      </li><li><br/>• support in the process of employment reduction;
      </li><li><br/>• preparation of analyses and strategies resolving employee-employer disputes in order to minimize the risks involved;
      </li><li><br/>• introducing employee stock options plans (ESOP);
      </li><li><br/>• advice on employment issues, including those arising during mergers, acquisitions and business transformations.</li></ul>  ],
    parg6: [
      <ul><li>• experience in labour law, both in litigation and in day-to-day business;
      </li><li><br/>• up-to-date knowledge on changes in labour law and labour market;
      </li><li><br/>• clear and timely communication with the Client to efficiently resolve labour issues;
      </li><li><br/>• cooperation with entities outside the legal industry, enabling effective analysis of the economic effects of actions taken in the area of labour law.</li></ul>
    ],

    buttonText: "Telefon",
    buttonVariant: "outlined",
    buttonText2: "Email",
    buttonVariant2: "outlined",
    buttonText3: "LinkedIn",
    buttonVariant3: "outlined",
  },
  {
    pic: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80",

    tab: "Compliance / Governance",
    index: 8,
    icon: <PhoneIcon />,
    bgPic:
      "https://images.unsplash.com/photo-1562654501-a0ccc0fc3fb1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80",
    parg1: "Compliance / Governance",
    parg2: (
      <p>
    Nowadays, especially due to Poland's membership in the European Union, entrepreneurial activity is increasingly regulated. "Compliance / Governance" includes support for entrepreneurs in adapting their activities to current legal requirements. Legal services in this area primarily serve to minimize legal risks arising out of the aforementioned regulation of entrepreneurial activities. Addressing these risks correctly often requires proper configuration of corporate governance, i.e., processes and standards that set the framework for the operation of a given organization and affect its further development. 
      </p>
    ),
    parg3:
    <p>The implementation of the compliance system leads to business risk reduction, in particular the risk associated with criminal, civil and criminal tax liability. At the same time, the aforementioned implementation increases the level of trust in the entrepreneur from its contractors, shareholders, customers and other stakeholders. It also protects the entrepreneur from financial and reputational loss. In addition, the implementation of appropriate procedures may lead to increased revenues or lower expenses, and the appropriate structuring of corporate governance - tailored to the individual needs of a given entity - to avoidance of internal disputes within the enterprise.</p>,
    parg4: (
      <p>Our "Compliance / Governance" practice includes, but is not limited to, the following services:</p>
    ),
    parg5: [
      <ul><li>• analysis of the business to propose an appropriate legal form;
      </li><li><br/>• conducting audits (due diligence) of applied procedures (including data protection (GDPR), consumer protection or procedures related to counteracting money laundering (AML)) to identify sources of and minimize risk as well as to avoid consequences of non-compliance with existing legal requirements;
      </li><li><br/>• drafting policies and internal procedures, including on verification of existing and new contractors;
      </li><li><br/>• advice for company authorities and other entities on performance of their legal obligations, including those of a fiduciary nature;
      </li><li><br/>• corporate processes optimization;
      </li><li><br/>• drafting and support in implementation of procedures to increase efficiency of the business, including support in structuring corporate governance.</li></ul>  ],
    parg6: [
      <ul><li>• experience in providing ongoing legal services to entrepreneurs, including adapting the business activity to new legal regulations;
      </li><li><br/>• experience in conducting and managing (including within corporate bodies) non-legal businesses;
      </li><li><br/>• ongoing monitoring of changes in law that affects business, enabling efficient adaptation to new legal regulations;
      </li><li><br/>• clear and timely communication with the Client to present and implement new legal requirements within the Client’s business.</li></ul>
      
    ],

    buttonText: "Telefon",
    buttonVariant: "outlined",
    buttonText2: "Email",
    buttonVariant2: "outlined",
    buttonText3: "LinkedIn",
    buttonVariant3: "outlined",
  },
];

const text = [
  {
    tab: "Legal Services for Entrepreneurs",
    icon: <Employer sx={{ fontSize: "80px" }} />,
    id: 0,
  },
  {
    tab: "Litigation",
    icon: <Court sx={{ fontSize: "80px" }} />,
    id: 1,
  },
  {
    tab: "Corporate Law",
    icon: <Spolka sx={{ fontSize: "80px" }} />,
    id: 2,
  },
  {
    tab: "Mergers & Acquisitions (M&A) / Investments",
    icon: <Transaction sx={{ fontSize: "80px" }} />,
    id: 3,
  },
  {
    tab: "IT / New Technologies",
    icon: <It sx={{ fontSize: "80px" }} />,
    id: 4,
  },
  {
    tab: "Real Estate",
    icon: <Realestate sx={{ fontSize: "80px" }} />,
    id: 5,
  },
  {
    tab: "Insolvency & Restructuring",
    icon: <Upadlosc sx={{ fontSize: "80px" }} />,
    id: 6,
  },
  {
    tab: "Labor Law",
    icon: <Employee sx={{ fontSize: "80px" }} />,
    id: 7,
  },
  {
    tab: "Compliance / Governance",
    icon: <PhoneIcon sx={{ fontSize: "80px" }} />,
    id: 8,
  },
];
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function BasicTabs() {
  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView();

  useEffect(() => {
    AOS.init({
      duration: 3000,
    });
  }, []);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [expanded, setExpanded] = React.useState(false);

  const handleSwitch = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <Container
        maxWidth="l"
        sx={{
          position: "fixed",
          zIndex: 5,
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
            xl: "block",
          },
        }}
      >
        <Box backgroundColor="white">
         
        </Box>
      </Container>
      <Container
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
            xl: "block",
          },
        }}
        maxWidth="lg"
      >
        <Box
          sx={{
            pt: 3,
            pb: 3,
            minHeight: "20vh",
          }}
        >
          <Container
            data-aos="fade-up"
            disableGutters
            component="main"
            sx={{
              pt: 0,
              pb: 0,
              // backgroundColor: "secondary.main"
            }}
          >
            <Container
              sx={{
                m: 0.2,
                py: 5,
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
              }}
            ></Container>

            <Typography
              variant="h5"
              align="justify"
              color=""
              component="p"
               
            >
              <br />
              KMT Kozubek Matusiak . We are a law firm created to provide comprehensive business support so that entrepreneurs may focus fully on their own business. We are a trusted legal partner to our Clients, carefully listening to their needs in order to deliver the best solutions.
            </Typography>
          </Container>
        </Box>

        <Box
          sx={{
            // backgroundImage: `url(https://images.unsplash.com/photo-1497215728101-856f4ea42174?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80)`,
            // backgroundSize: "cover",
            // backgroundPosition: "center",
            // minHeight: "80vh",
            // alignItems: "center",
            // py: 10,
            // backgroundColor: "secondary.main",
            minHeight: "80vh",
          }}
        >
          <Container
            data-aos="fade-down"
            sx={{
              pt: 5,
              pb: 22,
              // backgroundColor: "secondary.main",
            }}
          >
            <Typography
              component="h4"
              variant="h4"
              align="center"
              color=""
               
              sx={{
                backgroundColor: "primary",
              }}
            >
              Practice
              <hr />
            </Typography>
            <Tabs>
              <Grid
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "flex",
                    lg: "flex",
                    xl: "flex",
                  },
                }}
                container
                spacing={0}
                alignItems=""
              >
                {text.map((tier) => (
                  <Grid item key={tier.tab} xs={12} md={6} lg={4}>
                    <Button
                      onClick={() => {
                        executeScroll();
                        setValue(tier.id);
                      }}
                      id={tier.id}
                      size="large"
                      color="third"
                      sx={{
                        "&:hover": {
                          backgroundColor: "secondary.light",
                        },
                      }}
                    >
                      <Typography color="grey" component="h4" variant="h6">
                        {tier.icon}
                        <br />
                        {tier.tab}
                      </Typography>
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Tabs>
          </Container>
        </Box>
      </Container>
      <div ref={myRef}>
        <Box
          sx={{
            flexGrow: 1,
            backgroundColor: "white",
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            minHeight: "101vh",
            minWidth: "102vw",
            zIndex: -2,
            pt: 12,
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{
              borderRight: 1,
              borderColor: "divider",
              width: "20vw",
              py: 4,
            }}
          >
            <Tab
              sx={{ py: 1 }}
              icon={<Employer />}
              label="Legal Services for Entrepreneurs"
              {...a11yProps(0)}
            />
            <Tab
              sx={{ py: 1 }}
              icon={<Court />}
              label="Litigation"
              {...a11yProps(1)}
            />
            <Tab
              sx={{ py: 1 }}
              icon={<Spolka />}
              label="Corporate Law"
              {...a11yProps(2)}
            />
            <Tab
              sx={{ py: 1 }}
              icon={<Transaction />}
              label="Mergers & Acquisitions (M&A) / Investments"
              {...a11yProps(3)}
            />
            <Tab
              sx={{ py: 1 }}
              icon={<It />}
              label="IT / New Technologies"
              {...a11yProps(4)}
            />
            <Tab
              sx={{ py: 1 }}
              icon={<Realestate />}
              label="Real Estate"
              {...a11yProps(5)}
            />
            <Tab
              sx={{ py: 1 }}
              icon={<Upadlosc />}
              label="Insolvency & Restructuring"
              {...a11yProps(6)}
            />
            <Tab
              sx={{ py: 1 }}
              icon={<Employee />}
              label="Labor Law"
              {...a11yProps(7)}
            />
            <Tab
              sx={{ py: 1 }}
              icon={<PhoneIcon />}
              label="Compliance / Governance"
              {...a11yProps(8)}
            />
          </Tabs>
          {one.map((one) => (
            <TabPanel value={value} index={one.index}>
              <Box
                sx={{
                  // backgroundImage: `url(${one.bgPic})`,
                  // backgroundSize: "200vh",
                  backgroundPosition: "top",
                  backgroundRepeat: "no-repeat",
                  display: "flex",
                  minHeight: "120vh",
                  minWidth: "85vw",
                  mt: "-2vh",
                  ml: "-1vw",
                }}
              >
                <Grid container spacing={0} alignItems="">
                  <Grid item key="about" xs={0} sm={0} md={1} lg={2}>
                    <Card
                      data-aos="fade-right"
                      sx={{
                        backgroundColor: "white",
                        opacity: [0.9, 0.8, 0.7],
                        minHeight: "101vh",
                        alignItems: "right",
                      }}
                    ></Card>
                  </Grid>

                  <Grid item key="bio" xs={12} sm={12} md={11} lg={10}>
                    <Card
                      data-aos="fade-justify"
                      sx={{
                        // minHeight: "300vh",
                        minHeight: "101vh",
                        py: 5,
                      }}
                    >
                      <CardContent>
                        <Box
                          sx={{
                            justifyContent: "justify",
                            maxWidth: "md",
                            mb: 2,
                            minHeight: "50vh",
                          }}
                        >
                          <Typography
                            component="h6"
                            variant="h6"
                            align="justify"
                            color="text.priamry"
                            sx={{ py: 0 }}
                          >
                            {one.icon} | {one.tab}
                            <hr />
                          </Typography>

                          <Accordion
                            expanded={expanded === "panel1"}
                            onChange={handleSwitch("panel1")}
                            sx={{ py: 4 }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={one.parg1}
                              id={one.parg1}
                            >
                              <Typography
                                component="h6"
                                variant="h6"
                                align="justify"
                                color="text.priamry"
                              >
                                About
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                {/* <Typography
                                variant="h5"
                                align="justify"
                                color="text.secondary"
                                component="p"
                                 
                              >
                                {one.parg1}
                              </Typography> */}
                                <Typography
                                  variant="h7"
                                  align="justify"
                                  color="text.secondary"
                                  component="p"
                                   
                                >
                                  {one.parg2}
                                </Typography>
                             
                                <Typography
                                  variant="h7"
                                  align="justify"
                                  color="text.secondary"
                                  component="p"
                                   
                                >
                                  {one.parg3}
                                </Typography>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            sx={{ py: 4 }}
                            expanded={expanded === "panel2"}
                            onChange={handleSwitch("panel2")}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={one.parg2}
                              id={one.parg2}
                            >
                              {/* <img src={one} width="10%" /> */}

                              <Typography
                                component="h6"
                                variant="h6"
                                align="justify"
                                color="text.priamry"
                              >
                                <br />
                                Our Services
                                <br />
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                <Typography
                                  variant="h7"
                                  align="justify"
                                  color="text.secondary"
                                  component="p"
                                   
                                >
                                  {one.parg4}
                                </Typography>
                                <br />
                                <ul>
                                  <Typography
                                    component="p"
                                    variant="h7"
                                    align="justify"
                                    color="text.secondary"
                                    // key={line}
                                  >
                                    {one.parg5}
                                  </Typography>
                                </ul>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            sx={{ py: 4 }}
                            expanded={expanded === "panel3"}
                            onChange={handleSwitch("panel3")}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={one.parg3}
                              id={one.parg3}
                            >
                              {/* <img src={one} width="10%" /> */}

                              <Typography
                                component="h6"
                                variant="h6"
                                align="justify"
                                color="text.priamry"
                              >
                                Why KMT?
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                <Typography
                                  color="text.secondary"
                                  component="p"
                                  variant="h7"
                                  align="justify"
                                >
                                  {one.parg6}
                                </Typography>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                        {/* <CardActions>
                        <br/>
                        <Box
                          data-aos="zoom-in"
                          sx={{
                            maxWidth: "10vw",
                            display: "flex",
                            flexDirection: "column",
                            py:10,
                          }}
                        >
                          <Typography   variant="h5" component="h5">
                            Partner odpowiedzialny
                          </Typography>
                          <CardActionArea component="a" href={one.bio}>
                            <CardMedia
                              component="img"
                              sx={
                                {
                                  borderRadius:"100%",
                                  // 16:9
                                  // pt: "56.25%",
                                }
                              }
                              image={one.pic}
                              alt="random"
                            />
                          </CardActionArea>

                          <Button component="a" href={one.bio} size="small">
                            {one.title}
                          </Button>
                          <Button component="a" href={one.phone} size="small">
                            Telefon
                          </Button>
                          <Button component="a" href={one.email} size="small">
                            E-mail
                          </Button>
                        </Box>
                      </CardActions>
                    */}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
          ))}
        </Box>
      </div>
    </React.Fragment>
  );
}
function Accord() {
  return (
    <React.Fragment>
      <Typography
        variant="h5"
        align="center"
        color=""
        component="h1"
         
      >
        {" "}
        <hr />
        Practise
        <hr />
      </Typography>
      <Grid container spacing={1} alignItems="" sx={{ minHeight: "90vh" }}>
        {one.map((one) => (
          <Grid item key={one.title} xs={12} md={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={one.tab}
                id={one.tab}
              >
                <Typography
                  component="h6"
                  variant="h6"
                  align="justify"
                  color="text.priamry"
                >
                  {one.icon} |{one.tab}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Card
                  data-aos="fade-justify"
                  sx={{
                    minHeight: "300vh",
                    py: 5,
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        justifyContent: "justify",
                        maxWidth: "md",
                        mb: 2,
                      }}
                    >
                      <Typography
                        variant="h5"
                        align="justify"
                        color="text.secondary"
                        component="p"
                         
                      >
                        {one.parg1}
                      </Typography>
                      <br />
                      <Typography
                        variant="h7"
                        align="justify"
                        color="text.secondary"
                        component="p"
                         
                      >
                        {one.parg2}
                      </Typography>
                      <Typography
                        variant="h7"
                        align="justify"
                        color="text.secondary"
                        component="p"
                         
                      >
                        {one.parg3}
                      </Typography>
                      <br />
                      <Typography
                        variant="h5"
                        align="justify"
                        color="text.secondary"
                        component="p"
                         
                      >
                        Our Services
                        <br />
                      </Typography>
                      <br />
                      <Typography
                        variant="h7"
                        align="justify"
                        color="text.secondary"
                        component="p"
                         
                      >
                        {one.parg4}
                      </Typography>
                      <ul>
                        <Typography
                          component="p"
                          variant="h7"
                          align="justify"
                          color="text.secondary"
                          // key={line}
                        >
                          {one.parg5}
                        </Typography>
                      </ul>
                      <Typography
                        variant="h5"
                        align="justify"
                        color="text.secondary"
                        component="p"
                         
                      >
                        <br /> Why KMT?
                        <br />
                      </Typography>

                      <Typography
                        color="text.secondary"
                        component="p"
                        variant="h7"
                        align="justify"
                      >
                        {one.parg6}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
}

export default function Pricing() {
  return (
    <div>
      <Box component="span" sx={{ display: { xs: "none", sm: "block" } }}>
        <BasicTabs />
      </Box>
      <Box component="span" sx={{ display: { xs: "block", sm: "none" } }}>
       <Accord />
      </Box>
      <Helmet>
        
        <title>
        Specjalizacje
        </title>
        <meta name="description" content="W czym się specjalizujemy?"
        />
        <link rel="canonical" href="https://kmtlegal.pl/Specjalizacje"/>
      </Helmet>
    </div>
  );
}
