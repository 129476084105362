import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";



const root = ReactDOM.createRoot(document.getElementById("root"));

// ReactDOMServer.renderToString(<Handler />);
// const helmet = Helmet.renderStatic();
// import React from "react";  
// import { renderToString } from "react-dom/server";  
// import express from "express";  
// import App from "./src/App";  
// import { Helmet } from "react-helmet";  
// const app = express();    
  
// app.get("/*", (req, res) => {  
//   const app = renderToString(<App />);  
//   const helmet = Helmet.renderStatic();  
  
//   const html = `  
//   <!DOCTYPE html>  
//   <html ${helmet.htmlAttributes.toString()}>  
//     <head>  
//       ${helmet.title.toString()}  
//       ${helmet.meta.toString()}  
//       ${helmet.link.toString()}  
//     </head>  
//     <body ${helmet.bodyAttributes.toString()}>  
//       <div id="root">  
//         ${app}  
//       </div>  
//       </body>  
//   </html>  
// `;  
//     res.send(html);  
// });  
// app.listen(8000);  

root.render(
  // <React.StrictMode>

    <App />
  
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
